import { Spin } from 'antd';

const Loader = () => {
  return (
    <div className={'fixed left-0 top-0 w-[100vw] h-[100vh] bg-[#f5f5f5] z-[9999]'}>
      <Spin
        tip="Загрузка"
        size="large"
        style={{
          // background: 'red',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="content" />
      </Spin>
    </div>
  );
};

export default Loader;
