import { FC, useEffect } from 'react';
import { TFormModal, TImage, TUserCreator } from '../../core/types';
import { Form, Input, Modal, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { toast } from 'react-toastify';
import { getExceptionMessage, normalizePassword, validateURL } from '../../core/utils';
import ImageUpload from '../image-upload';
import {
  useCreateCreatorMutation,
  useLazyGetCurrentUserQuery,
  useUpdateCreatorMutation,
} from '../../store/services/users';
import { useGetCommunityTopicsQuery } from '../../store/services/community-topics';
import TextArea from 'antd/es/input/TextArea';

const CreatorsFormModal: FC<TFormModal<TUserCreator>> = ({ open, data, setClose, refetchCurrentUser }) => {
  const communityTopics = useGetCommunityTopicsQuery();
  const [createCreator, createCreatorStates] = useCreateCreatorMutation();
  const [updateCreator, updateCreatorStates] = useUpdateCreatorMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const isLoading = createCreatorStates.isLoading || updateCreatorStates.isLoading;
  const [form] = Form.useForm();
  const socialLinks = Form.useWatch('socialLinks', form);
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  useEffect(() => {
    form.resetFields();
    if (data && open) {
      form.setFieldsValue({
        ...data,
        ...data.creator,
        communityTopicId: data.creator?.communityTopic?.id,
      });
    }
  }, [open]);

  useEffect(() => {
    if (createCreatorStates.isSuccess) {
      toast.success('Креатор создан');
      handleCloseModal();
    }
  }, [createCreatorStates.isSuccess]);

  useEffect(() => {
    if (updateCreatorStates.isSuccess) {
      toast.success(refetchCurrentUser ? 'Данные изменены' : 'Данные креатора изменены');
      if (refetchCurrentUser) {
        getCurrentUser();
      }
      handleCloseModal();
    }
  }, [updateCreatorStates.isSuccess]);

  useEffect(() => {
    if (updateCreatorStates.isError) {
      toast.error(getExceptionMessage(updateCreatorStates.error));
    }
    if (createCreatorStates.isError) {
      toast.error(getExceptionMessage(createCreatorStates.error));
    }
  }, [updateCreatorStates.isError, createCreatorStates.isError]);

  const setImage = (data: TImage) => {
    form.setFieldValue('imageId', data.id);
    form.validateFields();
  };

  const handleChangeSocialLinks = (values: string[]) => {
    const filteredValues = values.filter((link, index) => {
      const isValidLink = validateURL(link);
      if (isValidLink && index <= 2) {
        return true;
      }
      if (!isValidLink) {
        toast.error('Пожалуйста, введите правильную ссылку.');
        return false;
      }

      if (index > 2) {
        toast.error('Вы можете добавить не более 3 ссылок.');
        return false;
      }
    });

    form.setFieldsValue({ socialLinks: filteredValues });
  };
  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();
      if (data) {
        return updateCreator({ id: data.id, body: formData });
      }
      return createCreator(formData);
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      title={`${data ? 'Редактировать' : 'Создать'} ${refetchCurrentUser ? 'профиль' : 'креатора'}`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      confirmLoading={isLoading}
      okText={data ? 'Сохранить' : 'Создать'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        <FormItem label={'Фото'} name={'imageId'} rules={[{ required: true, message: 'Вы не загрузили фото!' }]}>
          <ImageUpload
            className={'max-w-[230px] '}
            imageClassName={'aspect-[1/1]'}
            setImageId={setImage}
            data={data?.image}
          />
        </FormItem>
        <Form.Item
          label={'Полное имя'}
          name="username"
          rules={[
            { required: true, message: 'Укажите имя!' },
            {
              min: 3,
              message: 'Минимальная длина 3 знака!',
            },
          ]}
        >
          <Input size={'large'} type="text" placeholder="Полное имя" />
        </Form.Item>
        <Form.Item
          name="email"
          label={'Email'}
          rules={[
            { required: true, message: 'Укажите email!' },
            { type: 'email', message: 'Некорректный формат email!' },
          ]}
        >
          <Input size={'large'} type={'email'} placeholder="Email" />
        </Form.Item>
        {!data && (
          <Form.Item
            label={'Пароль'}
            name="password"
            getValueFromEvent={normalizePassword}
            rules={[
              { required: true, message: 'Укажите пароль!' },
              {
                min: 10,
                message: 'Минимальная длина 10 знаков!',
              },
            ]}
          >
            <Input.Password size={'large'} type="password" placeholder="Пароль" />
          </Form.Item>
        )}
        <Form.Item
          label={'Тема'}
          key={'communityTopicId'}
          name={'communityTopicId'}
          rules={[{ required: true, message: 'Вы не выбрали тему!' }]}
        >
          <Select allowClear size={'large'}>
            <Select.OptGroup label={'Тема'} />
            {communityTopics.data?.map(communityTopic => (
              <Select.Option key={communityTopic.id} value={communityTopic.id}>
                {communityTopic.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Ссылки на соц.сети'}
          key={'socialLinks'}
          name={'socialLinks'}
          rules={[{ required: true, message: 'Необходимо указать миниум 1 ссылку!' }]}
        >
          <Select
            size={'large'}
            open={false}
            mode="tags"
            value={socialLinks}
            style={{ width: '100%' }}
            placeholder="Ссылки на соц.сети"
            onChange={handleChangeSocialLinks}
            // options={options}
          />
        </Form.Item>
        <Form.Item
          label={'Справка'}
          name="authorInfo"
          rules={[
            { required: true, message: 'Поле обязательно!' },
            {
              min: 6,
              message: 'Минимальная длина 6 знаков!',
            },
          ]}
        >
          <TextArea size={'large'} placeholder={`Короткая справка ${refetchCurrentUser ? '' : 'об авторе'}`} />
        </Form.Item>
        <Form.Item
          label={`О чем ${refetchCurrentUser ? 'буду' : 'будет'} рассказывать`}
          name="blogDescription"
          rules={[
            { required: true, message: 'Поле обязательно!' },
            {
              min: 10,
              message: 'Минимальная длина 10 знаков!',
            },
          ]}
        >
          <TextArea
            size={'large'}
            style={{ minHeight: '120px' }}
            placeholder="Расширенная информация о том, что будет в блоге"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatorsFormModal;
