import { api } from '../index';

export interface ILogin {
  accessToken: string;
  refreshToken: string;
}

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    validateToken: builder.query<void, string>({
      query: accessToken => {
        if (accessToken === null) {
          throw new Error('AccessToken is null');
        }
        return {
          url: `/auth/validate-token`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ['auth'],
    }),
    signIn: builder.mutation<ILogin, { email: string; password: string }>({
      query: body => ({
        url: `/auth/sign-in`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ILogin) => {
        localStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('refreshToken', response.refreshToken);
        return response;
      },
      invalidatesTags: ['auth'],
    }),
  }),
});

export const { useSignInMutation, useValidateTokenQuery, useLazyValidateTokenQuery } = authApi;
