import { TUser } from '../../core/types';
import { Avatar, Dropdown, MenuProps } from 'antd';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { API_URL } from '../../core/config';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../core/hooks/redux';
import { logout } from '../../store/slices/auth/AuthSlice';
import { getImageUrl } from '../../core/utils';

type TUserProps = {
  user: TUser;
};
const User: FC<TUserProps> = ({ user }) => {
  const dispatch = useAppDispatch();

  if (!user) {
    return null;
  }

  const items: MenuProps['items'] = [
    {
      key: 'my-account',
      label: <Link to={'/settings/account'}>Настройки профиля</Link>,
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: 'exit',
      label: 'Выйти',
      icon: <LogoutOutlined />,
      onClick: () => dispatch(logout()),
      danger: true,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Avatar
        shape="square"
        size={48}
        className={'bg-white text-black'}
        src={getImageUrl(user.image)}
        icon={<UserOutlined />}
      />
    </Dropdown>
  );
};

export default User;
