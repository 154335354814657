import { Avatar, Button, Dropdown, MenuProps, Modal, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  ArticleStatusEnum,
  RolesEnum,
  TArticle,
  TCategory,
  TGetArticlesParams,
  TImage,
  TUser,
  TUserCreator,
} from '../../../core/types';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {
  ADMIN_ROLES,
  API_URL,
  CLIENT_URL,
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
  DEFAULT_RAISE_UP_ARTICLE_DATA,
  RU_DATE,
  RU_DATE_TIME,
} from '../../../core/config';
import UploadedImage from '../../ui/uploaded-image.ui';
import dayjs from 'dayjs';
import {
  useCancelRaiseUpArticleMutation,
  useChangeArticleStatusMutation,
  useDeleteArticleMutation,
  useGetArticlesQuery,
  useGetPreviewArticleUuidMutation,
  useRaiseUpArticleMutation,
} from '../../../store/services/articles';
import ArticlesTableFilters from './articles-table-filters';
import { useAppSelector } from '../../../core/hooks/redux';
import UserProfile from '../../user-profile';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getExceptionMessage, getImageUrl } from '../../../core/utils';
import ChangeArticleStatusFormModal from '../../ modals/change-article-status-form.modal';

const { confirm } = Modal;

export const articlesStatuses = {
  [ArticleStatusEnum.APPROVED]: {
    name: 'Одобрена',
    color: 'green',
  },
  [ArticleStatusEnum.NOT_APPROVED]: {
    name: 'Ожидает одобрения',
    color: 'orange',
  },
  [ArticleStatusEnum.EDITED]: {
    name: 'Изменена',
    color: 'volcano',
  },
  [ArticleStatusEnum.REJECTED]: {
    name: 'Отклонена',
    color: 'red',
  },
  [ArticleStatusEnum.EXCLUDED]: {
    name: 'Отключена',
    color: 'red',
  },
};

const ArticlesTable = () => {
  const { role } = useAppSelector(state => state.authReducer);
  const isAdmin = ADMIN_ROLES.includes(role);
  const navigate = useNavigate();
  const [params, setParams] = useState<TGetArticlesParams>({
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_LIMIT,
  });
  const articles = useGetArticlesQuery({ params });
  const [changeStatus, changeStatusStates] = useChangeArticleStatusMutation();
  const [raiseUp, raiseUpStates] = useRaiseUpArticleMutation();
  const [cancelRaiseUp, cancelRaiseUpStates] = useCancelRaiseUpArticleMutation();
  const [deleteArticle, deleteArticleStates] = useDeleteArticleMutation();
  const [getPreviewArticleUuid, getPreviewArticleUuidStates] = useGetPreviewArticleUuidMutation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedArticle, setSelectedArticle] = useState<TArticle | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<TUser | undefined>(undefined);

  useEffect(() => {
    if (role) {
      articles.refetch();
    }
  }, [role]);

  useEffect(() => {
    if (raiseUpStates.isSuccess) {
      toast.success('Статья поднята в топ');
    }
  }, [raiseUpStates.isSuccess]);

  useEffect(() => {
    if (deleteArticleStates.isSuccess) {
      toast.success('Статья удалена');
    }
  }, [deleteArticleStates.isSuccess]);

  useEffect(() => {
    if (getPreviewArticleUuidStates.isSuccess && getPreviewArticleUuidStates.data) {
      window.open(`${CLIENT_URL}articles/preview/${getPreviewArticleUuidStates.data.uuid}`);
    }
  }, [getPreviewArticleUuidStates.isSuccess]);

  useEffect(() => {
    if (raiseUpStates.isError) {
      toast.error(getExceptionMessage(raiseUpStates.error));
    }
    if (raiseUpStates.isError) {
      toast.error(getExceptionMessage(deleteArticleStates.error));
    }
    if (getPreviewArticleUuidStates.isError) {
      toast.error(getExceptionMessage(getPreviewArticleUuidStates.error));
    }
  }, [raiseUpStates.isError, deleteArticleStates.isError, getPreviewArticleUuidStates.isError]);

  const getColumns = (): ColumnsType<TArticle> => {
    const columns: ColumnsType<TArticle> = [
      {
        title: 'Фото',
        dataIndex: 'cardImage',
        key: 'cardImage',
        align: 'center',
        className: 'w-[110px]',
        render: (data: TImage) => {
          return (
            <div className={'w-[80px]'}>
              <UploadedImage data={data} preview={false} className={'aspect-[48/36]'} />
            </div>
          );
        },
      },
      {
        title: 'Заголовок',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        className: 'min-w-[250px] whitespace-pre-wrap',
      },
      {
        title: 'Категория',
        dataIndex: 'category',
        key: 'categoryName',
        align: 'center',
        className: 'min-w-[150px]',
        render: (category: TCategory) => {
          if (!category) {
            return <Tag color={'red'}>Категория не указана</Tag>;
          }
          return (
            <Tag color={category.isMainCategory ? 'purple' : 'blue'} className={'text-[12px] py-[3px] px-[10px]'}>
              {category.name}
            </Tag>
          );
        },
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        className: 'w-[210px]',
        render: (status: ArticleStatusEnum) => {
          const currentStatusProps = articlesStatuses[status];
          if (!currentStatusProps) {
            return null;
          }
          return (
            <Tag color={currentStatusProps.color} className={'text-[12px] py-[3px] px-[10px]'}>
              {currentStatusProps.name}
            </Tag>
          );
        },
      },
      {
        title: 'Статус публикации',
        dataIndex: '',
        key: 'publicationStatus',
        align: 'center',
        className: 'min-w-[150px]',
        render: (_, data: TArticle) => {
          const now = dayjs();
          const publicationDate = dayjs(data.publicationDate);
          if (now.isAfter(publicationDate) && data.status === ArticleStatusEnum.APPROVED) {
            return <Tag color={'green'}>Опубликовано {publicationDate.format(RU_DATE)}</Tag>;
          }
          if (now.isBefore(publicationDate) && data.status === ArticleStatusEnum.APPROVED) {
            return <Tag color={'orange'}>Будет опубликовано {publicationDate.format(RU_DATE)}</Tag>;
          }
          return '-';
        },
      },
    ];

    if (ADMIN_ROLES.includes(role)) {
      columns.push(
        {
          title: 'Поднято в топ',
          dataIndex: '',
          key: 'publicationStatus',
          align: 'center',
          className: 'min-w-[150px]',
          render: (_, data: TArticle) => {
            const defaultRaiseUpDate = dayjs(DEFAULT_RAISE_UP_ARTICLE_DATA);
            const raisedAtDate = dayjs(data.raisedAt);

            if (defaultRaiseUpDate.isSame(raisedAtDate)) {
              return '-';
            }

            return <Tag color={'orange'}>{raisedAtDate.format(RU_DATE_TIME)}</Tag>;
          },
        },
        {
          title: 'Креатор',
          dataIndex: 'creatorUser',
          key: 'creatorUser',
          align: 'center',
          className: 'min-w-[150px]',
          render: (creator: TUserCreator) => {
            if (!creator) {
              return '-';
            }
            return (
              <Button
                className={'h-fit flex flex-col gap-[5px] mx-auto'}
                onClick={() => {
                  setIsModalOpen(true);
                  setSelectedUser(creator);
                }}
              >
                <Avatar
                  shape="square"
                  size={48}
                  className={'bg-white text-black'}
                  src={getImageUrl(creator.image)}
                  icon={<UserOutlined />}
                />
                {creator.username}
              </Button>
            );
          },
        }
      );
    }

    if (role === RolesEnum.SUPER_ADMIN) {
      columns.push({
        title: 'Создал',
        dataIndex: 'createdBy',
        key: 'createdBy',
        align: 'center',
        className: 'min-w-[150px]',
        render: (user: TUser) => {
          if (!user) {
            return '-';
          }
          return (
            <Button
              className={'h-fit flex flex-col gap-[5px] mx-auto'}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedUser(user);
              }}
            >
              <Avatar
                shape="square"
                size={48}
                className={'bg-white text-black'}
                src={getImageUrl(user?.image)}
                icon={<UserOutlined />}
              />
              {user.username}
            </Button>
          );
        },
      });
    }
    columns.push({
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      className: 'w-[50px]',
      render: (_, data: TArticle) => {
        return (
          <Dropdown menu={{ items: renderDropDownItems(data) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    });
    return columns;
  };

  const handleCloseModal = () => {
    setSelectedArticle(undefined);
    setSelectedUser(undefined);
    setIsModalOpen(false);
  };

  const renderDropDownItems = (data: TArticle) => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        onClick: () => {
          navigate('/articles/edit/' + data.id);
        },
        label: 'Редактировать',
      },
    ];

    if (data.status !== ArticleStatusEnum.APPROVED || new Date(data.publicationDate) >= new Date()) {
      items.push({
        key: 'preview',
        onClick: () => {
          getPreviewArticleUuid({ id: data.id });
        },
        label: 'Предпросмотр',
      });
    } else {
      items.push({
        key: 'preview',
        onClick: () => {
          window.open(`${CLIENT_URL}articles/${data.pathName}`);
        },
        label: 'Открыть статью',
      });
    }

    if (isAdmin) {
      items.push({
        key: 'raiseUp',
        onClick: () => {
          confirm({
            title: 'Поднять статью в топ?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Поднять',
            onOk() {
              raiseUp({ id: data.id });
            },
          });
        },
        label: 'Поднять в топ',
      });
    }

    const defaultRaiseUpDate = dayjs(DEFAULT_RAISE_UP_ARTICLE_DATA);
    const raisedAtDate = dayjs(data.raisedAt);

    if (isAdmin && !defaultRaiseUpDate.isSame(raisedAtDate)) {
      items.push({
        key: 'cancelRaiseUp',
        onClick: () => {
          confirm({
            title: 'Аннулировать поднятие статьи в топ?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Аннулировать',
            onOk() {
              cancelRaiseUp({ id: data.id });
            },
          });
        },
        label: 'Аннулировать поднятие',
      });
    }

    if (isAdmin) {
      items.push({
        key: 'changeStatus',
        onClick: () => {
          setSelectedArticle(data);
          setIsModalOpen(true);
        },
        label: 'Изменить статус',
      });
    }
    if (!isAdmin && data.status !== ArticleStatusEnum.EXCLUDED) {
      items.push({
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Отключить отображение статьи в общей выдаче?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Отключить',
            onOk() {
              changeStatus({ id: data.id, status: ArticleStatusEnum.EXCLUDED });
            },
          });
        },
        label: 'Отключить отображение статьи',
        danger: true,
        dashed: true,
      });
    }
    if (!isAdmin && data.status === ArticleStatusEnum.EXCLUDED) {
      items.push({
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вернуть статью в общую выдачу?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Вернуть',
            onOk() {
              changeStatus({ id: data.id, status: ArticleStatusEnum.NOT_APPROVED });
            },
          });
        },
        label: 'Вернуть статью в выдачу',
      });
    }
    if (isAdmin) {
      items.push({
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить статью??',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Удалить',
            onOk() {
              deleteArticle({ id: data.id });
            },
          });
        },
        label: 'Удалить',
        danger: true,
      });
    }

    return items;
  };

  const handlePaginationChange = (page: number, limit: number) => {
    setParams({
      page,
      limit,
    });
  };

  return (
    <>
      <div className={'pt-[15px] pb-[25px]'}>
        <Button type="primary" size={'large'} onClick={() => navigate('/articles/create')}>
          Создать
        </Button>
      </div>
      <ArticlesTableFilters params={params} setParams={setParams} />
      <Table
        scroll={{ x: true }}
        pagination={{
          current: params.page,
          pageSize: params.limit,
          total: articles.data?.totalCount,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '25', '50', '100'],
        }}
        dataSource={articles.data?.data}
        columns={getColumns()}
        rowKey="id"
      />
      <Modal
        title={'Профиль'}
        open={selectedUser && isModalOpen}
        centered={true}
        footer={null}
        onCancel={handleCloseModal}
      >
        {selectedUser && <UserProfile data={selectedUser} />}
      </Modal>
      {selectedArticle && isAdmin && (
        <ChangeArticleStatusFormModal open={isModalOpen} setClose={handleCloseModal} data={selectedArticle} />
      )}
    </>
  );
};

export default ArticlesTable;
