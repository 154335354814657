import { api } from '../index';
import { TBannerItem, TSuccessRes } from '../../../core/types';

export const settingsApi = api.injectEndpoints({
  endpoints: builder => ({
    addBannerItem: builder.mutation<TSuccessRes, { body: { articleId: number } }>({
      query: ({ body }) => ({
        url: `/settings/banner/add-item/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['banner'],
    }),
    getBannerItems: builder.query<TBannerItem[], void>({
      query: () => ({
        url: `/settings/banner/items`,
      }),
      providesTags: ['banner'],
    }),
    raiseUpBannerItem: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/settings/banner/raise-up/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['banner'],
    }),
    deleteBannerItem: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/settings/banner/delete-item/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['banner'],
    }),
  }),
});

export const {
  useAddBannerItemMutation,
  useGetBannerItemsQuery,
  useRaiseUpBannerItemMutation,
  useDeleteBannerItemMutation,
} = settingsApi;
