import React from 'react';
import AdsTable from '../../components/tables/ads.table';
import CustomTitle from '../../components/ui/custom-title';

const AdsPage = () => {
  return (
    <>
      <CustomTitle>Реклама</CustomTitle>
      <AdsTable />
    </>
  );
};

export default AdsPage;
