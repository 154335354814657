import React, { useState } from 'react';
import { useGetCurrentUserQuery } from '../../store/services/users';
import { Button } from 'antd';
import CreatorsFormModal from '../../components/ modals/creators-form.modal';
import AdminsFormModal from '../../components/ modals/admins-form.modal';
import { RolesEnum, TUserCreator } from '../../core/types';
import ChangePasswordFormModal from '../../components/ modals/change-password-form.modal';
import CustomTitle from '../../components/ui/custom-title';
import { useAppSelector } from '../../core/hooks/redux';
import { skipToken } from '@reduxjs/toolkit/query';
import UserProfile from '../../components/user-profile';

const AccountPage = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);
  const { role } = useAppSelector(state => state.authReducer);
  const { data } = useGetCurrentUserQuery(role === RolesEnum.PUBLIC ? skipToken : undefined);
  const isCreator = data && data.role === RolesEnum.CREATOR && data.creator;
  const isAdmin = data && [RolesEnum.ADMIN, RolesEnum.SUPER_ADMIN].includes(data.role) && !data.creator;

  const handleCloseModal = () => {
    setIsFormModalOpen(false);
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <CustomTitle>Настройки аккаунта</CustomTitle>
      <div className={'my-[20px] flex flex-wrap gap-[20px]'}>
        <Button type="primary" size={'large'} onClick={() => setIsFormModalOpen(true)}>
          Редактировать профиль
        </Button>
        <Button type="primary" size={'large'} onClick={() => setIsChangePasswordModalOpen(true)}>
          Сменить пароль
        </Button>
      </div>
      <UserProfile data={data} />
      {isCreator && (
        <CreatorsFormModal
          open={isFormModalOpen}
          setClose={handleCloseModal}
          data={data as TUserCreator}
          refetchCurrentUser={true}
        />
      )}
      {isAdmin && (
        <AdminsFormModal open={isFormModalOpen} setClose={handleCloseModal} data={data} refetchCurrentUser={true} />
      )}
      <ChangePasswordFormModal open={isChangePasswordModalOpen} setClose={() => setIsChangePasswordModalOpen(false)} />
    </>
  );
};

export default AccountPage;
