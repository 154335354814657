import { Avatar, Input, Select, Spin } from 'antd';
import { ADMIN_ROLES, API_URL } from '../../../core/config';
import { UserOutlined } from '@ant-design/icons';
import {
  ArticleStatusEnum,
  RolesEnum,
  TGetArticlesParams,
  TGetCreatorsParams,
  TUserCreator,
  UserStatusEnum,
} from '../../../core/types';
import { FC, UIEvent, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useGetCategoriesQuery } from '../../../store/services/categories';
import { useLazyGetCreatorsQuery } from '../../../store/services/users';
import { useAppSelector } from '../../../core/hooks/redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { articlesStatuses } from './articles.table';
import { getImageUrl } from '../../../core/utils';

type TArticlesTableFilters = {
  setParams: (param: TGetArticlesParams | ((currentParams: TGetArticlesParams) => TGetArticlesParams)) => void;
  params: TGetArticlesParams;
};

const ArticlesTableFilters: FC<TArticlesTableFilters> = ({ setParams, params }) => {
  const { role } = useAppSelector(state => state.authReducer);
  const isAdmin = ADMIN_ROLES.includes(role);
  const categories = useGetCategoriesQuery(!isAdmin ? skipToken : undefined);
  const [fetchCreators, fetchCreatorsStates] = useLazyGetCreatorsQuery();
  const [creators, setCreators] = useState<TUserCreator[] | []>([]);
  const [creatorsParams, setCreatorsParams] = useState<TGetCreatorsParams>({
    status: UserStatusEnum.APPROVED,
    page: 1,
    limit: 10,
  });

  const fetchMoreCreators = useCallback(() => {
    fetchCreators({ params: creatorsParams });
  }, [fetchCreators, creatorsParams]);

  useEffect(() => {
    if (role && isAdmin) {
      fetchMoreCreators();
    }
  }, [creatorsParams, fetchMoreCreators, role]);

  useEffect(() => {
    if (fetchCreatorsStates.data?.data) {
      const data = fetchCreatorsStates.data?.data;
      setCreators(prevCreators => [...prevCreators, ...(data || [])]);
    }
  }, [fetchCreatorsStates.data]);

  const handleScrollCreators = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !fetchCreatorsStates.isLoading &&
      fetchCreatorsStates.data?.hasNextPage
    ) {
      setCreatorsParams(prevState => ({ ...prevState, page: (prevState.page || 1) + 1 }));
    }
  };
  const handleStatusChange = (status: ArticleStatusEnum) => {
    setParams(prevState => ({
      ...prevState,
      status: status ? status : undefined,
    }));
  };

  const handleCategoryChange = (id: number) => {
    setParams(prevState => ({
      ...prevState,
      categoryId: id ? id : undefined,
    }));
  };
  const handleCreatorChange = (id: number) => {
    setParams(prevState => ({
      ...prevState,
      creatorId: id ? id : undefined,
    }));
  };

  const handleSearchArticlesByTitle = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setParams(prevState => ({ ...prevState, page: 1, title: value }));
  }, 500);

  const handleSearchCreator = debounce(value => {
    setCreatorsParams(prevState => ({ ...prevState, page: 1, username: value }));
    setCreators([]);
  }, 500);

  return (
    <div className={'grid grid-cols-min-250-auto gap-[15px] max-w-[1045px] pb-[25px]'}>
      <Input allowClear onChange={handleSearchArticlesByTitle} placeholder={'Поиск по заголовку'} size={'large'} />
      <Select allowClear size={'large'} value={params.status} placeholder="Статус" onChange={handleStatusChange}>
        {Object.entries(articlesStatuses).map(([key, status]) => (
          <Select.Option key={key} value={key}>
            {status.name}
          </Select.Option>
        ))}
      </Select>
      {isAdmin && (
        <>
          <Select
            allowClear
            size={'large'}
            value={params.categoryId}
            placeholder="Категория"
            onChange={handleCategoryChange}
          >
            {categories.data?.map(category => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
          <Select
            allowClear
            showSearch
            size={'large'}
            onSearch={handleSearchCreator}
            notFoundContent={fetchCreatorsStates.isLoading ? <Spin size="small" /> : null}
            filterOption={false}
            placeholder="Креатор"
            onPopupScroll={handleScrollCreators}
            onChange={handleCreatorChange}
            dropdownRender={menu => (
              <>
                <Select.OptGroup label={'Креатор'} />
                {menu}
                {fetchCreatorsStates.isLoading && <Spin size="small" />}
              </>
            )}
          >
            {creators.map(creator => (
              <Select.Option key={creator.id} value={creator.id} label={creator.username}>
                <div className={'flex gap-3 items-center'}>
                  <Avatar src={getImageUrl(creator?.image)} icon={<UserOutlined />} className={'flex-shrink-0'} />
                  <span className={'pr-2'}>{creator.username}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </>
      )}
    </div>
  );
};

export default ArticlesTableFilters;
