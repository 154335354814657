import { api } from '../index';
import { TCommunityTopic, TCreateCommunityTopic, TSuccessRes, TUpdateCommunityTopic } from '../../../core/types';

export const communityTopicsApi = api.injectEndpoints({
  endpoints: builder => ({
    createCommunityTopic: builder.mutation<TSuccessRes, TCreateCommunityTopic>({
      query: body => ({
        url: `/community-topics/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['community-topics'],
    }),
    getCommunityTopics: builder.query<TCommunityTopic[], void>({
      query: () => ({
        url: `/community-topics`,
      }),
      providesTags: ['community-topics'],
    }),
    updateCommunityTopic: builder.mutation<TSuccessRes, { id: number; body: TUpdateCommunityTopic }>({
      query: ({ id, body }) => ({
        url: `/community-topics/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['community-topics'],
    }),
    deleteCommunityTopic: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/community-topics/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['community-topics'],
    }),
  }),
});

export const {
  useCreateCommunityTopicMutation,
  useGetCommunityTopicsQuery,
  useUpdateCommunityTopicMutation,
  useDeleteCommunityTopicMutation,
} = communityTopicsApi;
