import { FC, useEffect } from 'react';
import { TCategory, TFormModal } from '../../core/types';
import { Form, Input, Modal } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { TCreateCategoryForm, TUpdateCategoryForm } from '../../core/types/forms/categories';
import { useCreateCategoryMutation, useUpdateCategoryMutation } from '../../store/services/categories';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';

const CategoriesFormModal: FC<TFormModal<TCategory>> = ({ open, data, setClose }) => {
  const [createCategory, createCategoryStates] = useCreateCategoryMutation();
  const [updateCategory, updateCategoryStates] = useUpdateCategoryMutation();
  const [form] = Form.useForm();
  const isLoading = createCategoryStates.isLoading || updateCategoryStates.isLoading;
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  useEffect(() => {
    if (data && open) {
      form.setFieldsValue(data);
    }
  }, [data]);

  useEffect(() => {
    if (createCategoryStates.isSuccess) {
      toast.success('Категория создана');
      handleCloseModal();
    }
  }, [createCategoryStates.isSuccess]);

  useEffect(() => {
    if (updateCategoryStates.isSuccess) {
      toast.success('Категория изменена');
      handleCloseModal();
    }
  }, [updateCategoryStates.isSuccess]);

  useEffect(() => {
    if (updateCategoryStates.isError) {
      toast.error(getExceptionMessage(updateCategoryStates.error));
    }
    if (createCategoryStates.isError) {
      toast.error(getExceptionMessage(createCategoryStates.error));
    }
  }, [updateCategoryStates.isError, createCategoryStates.isError]);
  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      if (data) {
        return updateCategory({ id: data.id, body: formData });
      }
      return createCategory(formData);
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      title={`${data ? 'Редактировать' : 'Создать'} категорию`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={() => form.submit()}
      confirmLoading={isLoading}
      onCancel={handleCloseModal}
      okText={data ? 'Сохранить' : 'Создать'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        <FormItem
          label={'Название'}
          name={'name'}
          rules={[
            {
              required: true,
              message: 'Поле обязательное!',
            },
            {
              min: 3,
              message: 'Минимальная длина 3 знаков!',
            },
          ]}
        >
          <Input size={'large'} />
        </FormItem>
        <FormItem
          label={'Описание'}
          name={'description'}
          rules={[
            {
              required: true,
              message: 'Поле обязательное!',
            },
            {
              min: 10,
              message: 'Минимальная длина 10 знаков!',
            },
          ]}
        >
          <TextArea size={'large'} />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default CategoriesFormModal;
