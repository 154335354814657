import { FC, useEffect } from 'react';
import { TArticle, TFormModal } from '../../core/types';
import { Form, Modal, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';
import { useChangeArticleStatusMutation } from '../../store/services/articles';
import { articlesStatuses } from '../tables/articles/articles.table';

const ChangeArticleFormModal: FC<TFormModal<TArticle>> = ({ open, data, setClose }) => {
  const [changeStatus, changeStatusStates] = useChangeArticleStatusMutation();
  const isLoading = changeStatusStates.isLoading;
  const [form] = Form.useForm();
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
    if (data) {
      form.setFieldsValue(data);
    }
  }, [open, data]);

  useEffect(() => {
    if (changeStatusStates.isSuccess) {
      toast.success('Статус изменен');
      handleCloseModal();
    }
  }, [changeStatusStates.isSuccess]);

  useEffect(() => {
    if (changeStatusStates.isError) {
      toast.error(getExceptionMessage(changeStatusStates.error));
    }
  }, [changeStatusStates.isError]);

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      if (data) {
        return changeStatus({ id: data.id, status: formData.status });
      }
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      title={`Изменить статус статьи`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmit}
      confirmLoading={isLoading}
      onCancel={handleCloseModal}
      okText={'Сохранить'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        <FormItem label={'Статус'} name={'status'} rules={[{ required: true, message: 'Вы не выбрали статус!' }]}>
          <Select allowClear size={'large'} placeholder="Статус">
            {Object.entries(articlesStatuses).map(([key, status]) => (
              <Select.Option key={key} value={key}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ChangeArticleFormModal;
