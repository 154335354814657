import React, { useEffect } from 'react';
import CustomTitle from '../../components/ui/custom-title';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetArticleByIdQuery,
  useLazyGetArticleByIdQuery,
  useLazyGetArticlesQuery,
} from '../../store/services/articles';
import ArticleForm from '../../components/forms/article.form';

const EditArticlePage = () => {
  const params = useParams();
  // const { data, isLoading } = useGetArticleByIdQuery({ id: Number(params.id) });
  const [getArticle, getArticleStates] = useLazyGetArticleByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (params?.id) {
      getArticle({ id: Number(params.id) });
    }
  }, [params?.id]);

  useEffect(() => {
    if (!getArticleStates.isLoading && getArticleStates.error) {
      navigate('/articles/all');
    }
  }, [getArticleStates.isLoading]);

  if (!getArticleStates.isLoading && !getArticleStates.data) {
    return null;
  }

  return (
    <>
      <CustomTitle>Редактировать статью</CustomTitle>
      <ArticleForm data={getArticleStates.data} />
    </>
  );
};

export default EditArticlePage;
