import React, { CSSProperties, FC } from 'react';
import { Image } from 'antd';
import { API_URL } from '../../core/config';
import { TImage } from '../../core/types';
import { getImageUrl } from '../../core/utils';

type TUploadedImage = {
  data: TImage;
  preview?: boolean;
  className?: string;
};
const UploadedImage: FC<TUploadedImage> = ({ data, preview = true, className = '' }) => {
  return (
    <Image
      preview={preview}
      src={getImageUrl(data)}
      className={`${className} p-[5px] rounded-[15px] border object-cover`}
    />
  );
};

export default UploadedImage;
