import { FC, useEffect } from 'react';
import { TFormModal } from '../../core/types';
import { Form, Input, Modal } from 'antd';
import { toast } from 'react-toastify';
import { getExceptionMessage, normalizePassword } from '../../core/utils';
import { useChangeUserPasswordMutation } from '../../store/services/users';
import { useAppDispatch, useAppSelector } from '../../core/hooks/redux';
import { logout } from '../../store/slices/auth/AuthSlice';

const ChangePasswordFormModal: FC<TFormModal> = ({ open, setClose }) => {
  const { user } = useAppSelector(state => state.authReducer);
  const dispatch = useAppDispatch();
  const [changePassword, changePasswordStates] = useChangeUserPasswordMutation();
  const isLoading = changePasswordStates.isLoading;
  const [form] = Form.useForm();
  const newPassword = Form.useWatch('newPassword', form);
  const oldPassword = Form.useWatch('oldPassword', form);
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  useEffect(() => {
    if (changePasswordStates.isSuccess) {
      toast.success('Пароль изменен, войдите повторно в систему');
      dispatch(logout());
      handleCloseModal();
    }
  }, [changePasswordStates.isSuccess]);

  useEffect(() => {
    if (changePasswordStates.isError) {
      toast.error(getExceptionMessage(changePasswordStates.error));
    }
  }, [changePasswordStates.isError]);

  const validateNewPassword = async (_: any, value: string) => {
    if (value !== newPassword) {
      throw new Error('Пароли не совпадают!');
    }
  };
  const validateDuplicatePassword = async (_: any, value: string) => {
    if (value === oldPassword) {
      throw new Error('Вы не можете указать тот же пароль!');
    }
  };

  const handleSubmit = async () => {
    if (isLoading || !user) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();
      const body = {
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
      };
      await changePassword({ id: user.id, body });
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      title={`Изменить пароль`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmit}
      confirmLoading={isLoading}
      onCancel={handleCloseModal}
      okText={'Сохранить'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        <Form.Item
          label={'Старый пароль'}
          name="oldPassword"
          getValueFromEvent={normalizePassword}
          rules={[
            { required: true, message: 'Укажите старый пароль!' },
            {
              min: 10,
              message: 'Минимальная длина 10 знаков!',
            },
          ]}
        >
          <Input.Password size={'large'} type="password" placeholder="Старый пароль" />
        </Form.Item>
        <Form.Item
          label={'Новый пароль'}
          name="newPassword"
          dependencies={['oldPassword']}
          getValueFromEvent={normalizePassword}
          rules={[
            { required: true, message: 'Укажите новый пароль!' },
            {
              min: 10,
              message: 'Минимальная длина 10 знаков!',
            },
            {
              validator: validateDuplicatePassword,
            },
          ]}
        >
          <Input.Password size={'large'} type="password" placeholder="Новый пароль" />
        </Form.Item>
        <Form.Item
          label={'Повторите новый пароль'}
          name="repeatNewPassword"
          dependencies={['newPassword']}
          getValueFromEvent={normalizePassword}
          rules={[
            { required: true, message: 'Обязательное поле!' },
            {
              min: 10,
              message: 'Минимальная длина 10 знаков!',
            },
            {
              validator: validateNewPassword,
            },
          ]}
        >
          <Input.Password size={'large'} type="password" placeholder="Повторите новый пароль" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordFormModal;
