import { jwtDecode } from 'jwt-decode';
import { TException, JwtPayload, RolesEnum, TImage } from '../types';
import { ILogin } from '../../store/services/auth';
import { errorTranslations } from '../translations';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import { IRoute } from '../../components/app-router';
import dayjs from 'dayjs';
import { API_URL, ISO_DATE } from '../config';
import { ChangeEvent } from 'react';
export const getUserDataFromAccessToken = (token: string) => {
  const user: JwtPayload = jwtDecode(token);
  const role = user.role;
  const email = user.email;
  const id = user.sub;

  return {
    user: {
      email,
      id,
    },
    role,
  };
};

export const getUserDataFromToken = (tokens: ILogin) => {
  const user: JwtPayload = jwtDecode(tokens.accessToken);
  const role = user.role;
  const email = user.email;
  const id = user.sub;

  return {
    user: {
      email,
      id,
    },
    role,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
  };
};

export const filterRoutesByRole = (routes: IRoute[], role: RolesEnum): IRoute[] => {
  return routes
    .filter(route => !route.allowedRoles || route.allowedRoles.includes(role))
    .map(route => ({
      ...route,
      children: route.children ? filterRoutesByRole(route.children, role) : undefined,
    }));
};

export const getExceptionMessage = (
  error: FetchBaseQueryError | SerializedError | undefined,
  customMessage?: string
): string => {
  if (!error) {
    return customMessage ?? 'Произошла ошибка, попробуйте позже';
  }

  if ('data' in error) {
    const errorData = error.data as TException;

    if (errorData && errorData.message) {
      return errorTranslations[errorData.message] || customMessage || 'Произошла ошибка, попробуйте позже';
    }
  }

  return customMessage ?? 'Произошла ошибка, попробуйте позже';
};

export const getValueFormDatePicker = (e: ReturnType<typeof dayjs>) => e?.format(ISO_DATE);
export const getValueFormDatePickerProps = (value?: ReturnType<typeof dayjs>) => ({
  value: value ? dayjs(value) : undefined,
});

export const validateURL = (url: string) => {
  const urlPattern = new RegExp(
    'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)'
  );
  return !!urlPattern.test(url);
};

export const normalizePassword = (event: ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  return value.replace(/\s/g, ''); // удаляем все пробелы и скрытые символы
};

export const getImageUrl = (image?: TImage): string | undefined => {
  if (!image) {
    return undefined;
  }
  return API_URL + (image.compressedFilePath ? image.compressedFilePath : image.originalFilePath || '');
};
