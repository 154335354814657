import { RolesEnum } from '../types';

export const ADMIN_ROLES = [RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN];

export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:4000/';
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL
  ? process.env.REACT_APP_CLIENT_URL
  : 'http://localhost:3000/';

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_LIMIT = 10;

export const ISO_DATE = 'YYYY-MM-DD HH:mm:ss.SSSSSS';
export const RU_DATE = 'DD.MM.YYYY';
export const RU_DATE_TIME = 'DD.MM.YYYY HH:mm';

export const DEFAULT_RAISE_UP_ARTICLE_DATA = '2020-02-02T00:00:00.000Z';
export const ADMINS_ROLES = [RolesEnum.ADMIN, RolesEnum.SUPER_ADMIN];
