export enum RolesEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  CREATOR = 'CREATOR',
  PUBLIC = 'PUBLIC',
}

export enum UserStatusEnum {
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  BLOCKED = 'BLOCKED',
}

export enum ArticleStatusEnum {
  APPROVED = 'APPROVED',
  EDITED = 'EDITED',
  NOT_APPROVED = 'NOT_APPROVED',
  REJECTED = 'REJECTED',
  EXCLUDED = 'EXCLUDED',
}
