import { RolesEnum, TUser } from '../core/types';
import React, { FC, Fragment } from 'react';
import UploadedImage from './ui/uploaded-image.ui';
import { CLIENT_URL } from '../core/config';
import { Tag } from 'antd';

type TUserProfileProps = {
  data: TUser;
};
const ItemTitle = ({ text }: { text: string }) => {
  return <h5 className={'text-[20px] font-bold inline-block'}>{text}: </h5>;
};
const UserProfile: FC<TUserProfileProps> = ({ data }) => {
  const isCreator = data.role === RolesEnum.CREATOR;

  return (
    <div className={'flex gap-[20px] flex-wrap max-w-[900px] text-[18px]'}>
      <div className={'w-full max-w-[350px] min-w-[250px]'}>
        <ul>
          {data.image && (
            <li>
              <UploadedImage data={data.image} className={'aspect-[1/1]'} />
            </li>
          )}
          <li>
            <ItemTitle text={'Имя'} /> {data.username}
          </li>
          <li>
            <ItemTitle text={'Email'} /> {data.email}
          </li>
        </ul>
      </div>
      {isCreator && data.creator && (
        <div className={'flex-grow min-w-[300px] max-w-[530px]'}>
          <ul className={'flex flex-col gap-[15px]'}>
            <li>
              <ItemTitle text={'Направление деятельности'} />{' '}
              {!data.creator.communityTopic ? (
                <Tag color={'red'} className={'text-[20px]'}>
                  Направление не установлено!
                </Tag>
              ) : (
                <Tag color={'geekblue'} className={'text-[18px] py-[3px]'}>
                  {data.creator.communityTopic.name}
                </Tag>
              )}
            </li>
            <li>
              <ItemTitle text={'Справка'} /> {data.creator.authorInfo}
            </li>
            <li>
              <ItemTitle text={'О чем буду рассказывать'} /> {data.creator.blogDescription}
            </li>
            <li>
              <ItemTitle text={'Ссылки на соц. сети'} />
              <br />
              {data.creator.socialLinks.map((link, index) => (
                <Fragment key={index}>
                  <span>{index + 1 + ') '}</span>
                  <a href={link} target={'_blank'} rel="noreferrer" className={'break-words'}>
                    {link}
                  </a>
                  <br />
                </Fragment>
              ))}
            </li>
            <li>
              <ItemTitle text={'Ссылка профиля'} />{' '}
              <a
                href={`${CLIENT_URL}community/creators/${data.creator?.communityTopic?.pathName}/${data.creator?.pathName}`}
                target={'_blank'}
                rel="noreferrer"
              >
                {data.creator.pathName}
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
