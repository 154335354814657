import { Button, Dropdown, Input, MenuProps, Modal, Select, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TGetCreatorsParams, TImage, TUserCreator, UserStatusEnum } from '../../core/types';
import { SettingOutlined } from '@ant-design/icons';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';
import { ADMIN_ROLES, CLIENT_URL, DEFAULT_PAGE, DEFAULT_PAGE_LIMIT } from '../../core/config';
import UploadedImage from '../ui/uploaded-image.ui';
import {
  useApproveUserMutation,
  useBlockUserMutation,
  useDeleteUserMutation,
  useGetCreatorsQuery,
  useRaiseUpCreatorMutation,
  useUnblockUserMutation,
} from '../../store/services/users';
import CreatorsFormModal from '../ modals/creators-form.modal';
import debounce from 'lodash.debounce';
import { useAppSelector } from '../../core/hooks/redux';
import UserProfile from '../user-profile';

const { confirm } = Modal;

const CreatorsTable = () => {
  const { role } = useAppSelector(state => state.authReducer);
  const isAdmin = ADMIN_ROLES.includes(role);
  const [params, setParams] = useState<TGetCreatorsParams>({
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_LIMIT,
  });
  const creators = useGetCreatorsQuery({ params });
  const [raiseUp, raiseUpStates] = useRaiseUpCreatorMutation();
  const [deleteCreator, deleteCreatorStates] = useDeleteUserMutation();
  const [blockCreator, blockCreatorStates] = useBlockUserMutation();
  const [unblockCreator, unblockCreatorStates] = useUnblockUserMutation();
  const [approveCreator, approveCreatorStates] = useApproveUserMutation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<'edit' | 'view-profile' | undefined>(undefined);
  const [selectedCreator, setSelectedCreator] = useState<TUserCreator | undefined>(undefined);

  const statuses = {
    [UserStatusEnum.BLOCKED]: {
      changeStatusTitle: 'Вы точно хотите разблокировать креатора?',
      changeStatusLabel: 'Разблокировать',
      statusText: 'Заблокирован',
      color: 'red',
      action: unblockCreator,
    },
    [UserStatusEnum.APPROVED]: {
      changeStatusTitle: 'Вы точно хотите заблокировать креатора?',
      changeStatusLabel: 'Заблокировать',
      statusText: 'Активный',
      color: 'green',
      action: blockCreator,
    },
    [UserStatusEnum.NOT_APPROVED]: {
      changeStatusTitle: 'Одобрить пользователя?',
      changeStatusLabel: 'Одобрить',
      statusText: 'Ожидает одобрения',
      color: 'orange',
      action: approveCreator,
    },
  };

  useEffect(() => {
    if (raiseUpStates.isSuccess) {
      toast.success('Креатор поднят в топ');
    }
  }, [raiseUpStates.isSuccess]);

  useEffect(() => {
    if (deleteCreatorStates.isSuccess) {
      toast.success('Креатор удален');
    }
  }, [deleteCreatorStates.isSuccess]);
  useEffect(() => {
    if (deleteCreatorStates.isError) {
      toast.error(getExceptionMessage(deleteCreatorStates.error));
    }
  }, [deleteCreatorStates.isError]);

  const columns: ColumnsType = [
    {
      title: 'Фото',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      className: 'w-[80px]',
      render: (data: TImage) => {
        return (
          <div className={'w-[70px]'}>
            <UploadedImage data={data} preview={false} className={'aspect-[1/1]'} />
          </div>
        );
      },
    },
    {
      title: 'Полное имя',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      className: 'min-w-[200px]',
    },
    {
      title: 'Ссылка',
      key: 'creator.pathName',
      align: 'center',
      className: 'min-w-[200px]',
      render: (_, data: TUserCreator) => {
        return (
          <a
            href={`${CLIENT_URL}community/creators/${data.creator?.communityTopic?.pathName}/${data.creator?.pathName}`}
            target={'_blank'}
            rel="noreferrer"
          >
            {data.creator?.pathName}
          </a>
        );
      },
    },
    {
      title: 'Статус',
      key: 'status',
      align: 'center',
      className: 'min-w-[50px]',
      render: (_, data: TUserCreator) => {
        const status = statuses[data.status];

        return <Tag color={status.color}>{status.statusText}</Tag>;
      },
    },
    {
      title: 'Направление',
      dataIndex: ['creator', 'communityTopic', 'name'],
      key: 'creator.communityTopic.name',
      align: 'center',
      className: 'min-w-[50px]',
      render: (value: string) => {
        if (!value) {
          return <Tag color={'red'}>Направление не установлено!</Tag>;
        }
        return <Tag color={'geekblue'}>{value}</Tag>;
      },
    },
    {
      title: 'Профиль',
      key: 'creator.profile',
      align: 'center',
      className: 'min-w-[100px]',
      render: (_, data: TUserCreator) => {
        return (
          <Button
            onClick={() => {
              setSelectedCreator(data);
              setIsModalOpen(true);
              setModalMode('view-profile');
            }}
          >
            Открыть профиль
          </Button>
        );
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      className: 'w-[50px]',
      render: (_, data: TUserCreator) => {
        return (
          <Dropdown menu={{ items: renderDropDownItems(data) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setSelectedCreator(undefined);
    setIsModalOpen(false);
    setModalMode(undefined);
  };

  const renderDropDownItems = (data: TUserCreator) => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        onClick: () => {
          const creator = creators.data?.data?.find(el => el.id === data.id);
          setSelectedCreator(creator);
          setModalMode('edit');
          setIsModalOpen(true);
        },
        label: 'Редактировать',
      },
      {
        key: 'change_status',
        onClick: () => {
          confirm({
            title: statuses[data.status].changeStatusTitle,
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              statuses[data.status].action({ id: data.id });
            },
          });
        },
        label: statuses[data.status].changeStatusLabel,
      },
      {
        key: 'raiseUp',
        onClick: () => {
          confirm({
            title: 'Поднять креатора в топ?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Поднять',
            onOk() {
              raiseUp({ id: data.id });
            },
          });
        },
        label: 'Поднять в топ',
      },
      {
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить креатора?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Удалить',
            onOk() {
              deleteCreator({ id: data.id });
            },
          });
        },
        label: 'Удалить',
        danger: true,
      },
    ];

    return items;
  };

  const handlePaginationChange = (page: number, limit: number) => {
    setParams({
      page,
      limit,
    });
  };

  const handleStatusChange = (status: UserStatusEnum) => {
    setParams({
      ...params,
      status: status ? status : undefined,
    });
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setParams({
        page: DEFAULT_PAGE,
        limit: DEFAULT_PAGE_LIMIT,
        status: undefined,
        username: value,
      });
    }, 500),
    []
  );

  const handleChangeUserNameFilter = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  return (
    <>
      <div className={'pt-[15px] pb-[25px]'}>
        <Button type="primary" size={'large'} onClick={() => setIsModalOpen(true)}>
          Создать
        </Button>
      </div>
      <div className={'grid grid-cols-min-250-auto gap-[15px] max-w-[1045px] pb-[25px]'}>
        <Input allowClear onChange={handleChangeUserNameFilter} placeholder={'Поиск по имени'} size={'large'} />
        <Select
          allowClear
          size={'large'}
          value={params.status}
          placeholder="Выберите статус"
          onChange={handleStatusChange}
        >
          {Object.entries(statuses).map(([key, status]) => (
            <Select.Option key={key} value={key}>
              {status.statusText}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Table
        scroll={{ x: true }}
        pagination={{
          current: params.page,
          pageSize: params.limit,
          total: creators.data?.totalCount,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '25', '50', '100'],
        }}
        dataSource={creators.data?.data}
        columns={columns}
        rowKey="id"
      />
      <Modal
        title={'Профиль'}
        open={selectedCreator && isModalOpen && modalMode === 'view-profile'}
        centered={true}
        footer={null}
        onCancel={handleCloseModal}
      >
        {selectedCreator && <UserProfile data={selectedCreator} />}
      </Modal>
      <CreatorsFormModal
        open={(isModalOpen && modalMode === 'edit') || (isModalOpen && !modalMode)}
        setClose={handleCloseModal}
        data={selectedCreator}
      />
    </>
  );
};

export default CreatorsTable;
