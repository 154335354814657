import CommunityTopicsTable from '../../components/tables/community-topics.table';
import CustomTitle from '../../components/ui/custom-title';

const CommunityTopicsPage = () => {
  return (
    <>
      <CustomTitle>Комьюнити темы</CustomTitle>
      <CommunityTopicsTable />
    </>
  );
};

export default CommunityTopicsPage;
