import { Button, Dropdown, MenuProps, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TCommunityTopic } from '../../core/types';
import { SettingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';
import { useDeleteCommunityTopicMutation, useGetCommunityTopicsQuery } from '../../store/services/community-topics';
import CommunityTopicsFormModal from '../ modals/community-topics-form.modal';

const { confirm } = Modal;

const CommunityTopicsTable = () => {
  const communityTopics = useGetCommunityTopicsQuery();
  const [deleteCommunityTopic, deleteCommunityTopicStates] = useDeleteCommunityTopicMutation();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [selectedCommunityTopic, setSelectedCommunityTopic] = useState<TCommunityTopic | undefined>(undefined);

  useEffect(() => {
    if (deleteCommunityTopicStates.isSuccess) {
      toast.success('Комьюнити тема удалена');
    }
  }, [deleteCommunityTopicStates.isSuccess]);
  useEffect(() => {
    if (deleteCommunityTopicStates.isError) {
      toast.error(getExceptionMessage(deleteCommunityTopicStates.error));
    }
  }, [deleteCommunityTopicStates.isError]);

  const columns: ColumnsType = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      className: 'w-[50px]',
      render: (_, data: TCommunityTopic) => {
        return (
          <Dropdown menu={{ items: renderDropDownItems(data.id) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setSelectedCommunityTopic(undefined);
    setIsFormModalOpen(false);
  };

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        onClick: () => {
          setSelectedCommunityTopic(communityTopics.data?.find(el => el.id === id));
          setIsFormModalOpen(true);
        },
        label: 'Редактировать',
      },
      {
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить комьюнити тему?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Удалить',
            onOk() {
              deleteCommunityTopic({ id });
            },
          });
        },
        label: 'Удалить',
        danger: true,
      },
    ];

    return items;
  };

  return (
    <>
      <div className={'pt-[15px] pb-[25px]'}>
        <Button type="primary" size={'large'} onClick={() => setIsFormModalOpen(true)}>
          Создать
        </Button>
      </div>
      <Table scroll={{ x: true }} pagination={false} dataSource={communityTopics.data} columns={columns} rowKey="id" />
      <CommunityTopicsFormModal open={isFormModalOpen} setClose={handleCloseModal} data={selectedCommunityTopic} />
    </>
  );
};

export default CommunityTopicsTable;
