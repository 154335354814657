import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import AuthForm from './forms/auth.form';
import { useAppSelector } from '../core/hooks/redux';
import { FC, ReactNode, Suspense, useEffect } from 'react';
import Loader from './loader';
import { RolesEnum } from '../core/types';
import Home from '../pages';
import AdminsPage from '../pages/users/admins';
import { AlignLeftOutlined, DollarOutlined, HomeOutlined, SettingOutlined, TeamOutlined } from '@ant-design/icons';
import { ADMIN_ROLES } from '../core/config';
import { filterRoutesByRole } from '../core/utils';
import Categories from '../pages/settings/categories';
import CommunityTopicsPage from '../pages/settings/community-topics';
import AdsPage from '../pages/ads';
import CreatorsPage from '../pages/users/creators';
import AccountPage from '../pages/settings/account';
import AllArticlesPage from '../pages/articles/all';
import EditArticlePage from '../pages/articles/edit';
import CreateArticlePage from '../pages/articles/create';
import BannerSettingsPage from '../pages/settings/banner';

export interface IRoute {
  label: string;
  path: string;
  key: string;
  component: ReactNode;
  icon?: ReactNode;
  showInMenu?: boolean;
  allowedRoles?: RolesEnum[];
  children?: IRoute[];
}

export const routesConfig: IRoute[] = [
  {
    label: 'Главная',
    path: '/',
    key: '/',
    component: <Home />,
    icon: <HomeOutlined />,
    showInMenu: true,
    allowedRoles: [...ADMIN_ROLES],
  },
  {
    label: 'Реклама',
    path: '/ads',
    key: '/ads',
    component: <AdsPage />,
    icon: <DollarOutlined />,
    showInMenu: true,
    allowedRoles: ADMIN_ROLES,
  },
  {
    label: 'Статьи',
    path: '/articles',
    key: '/articles',
    component: <Home />,
    icon: <AlignLeftOutlined />,
    showInMenu: true,
    allowedRoles: [...ADMIN_ROLES, RolesEnum.CREATOR],
    children: [
      {
        label: 'Все статьи',
        path: '/articles/all',
        key: '/articles/all',
        component: <AllArticlesPage />,
        allowedRoles: [...ADMIN_ROLES, RolesEnum.CREATOR],
      },
      {
        label: 'Создать статью',
        path: '/articles/create',
        key: '/articles/create',
        component: <CreateArticlePage />,
        allowedRoles: [...ADMIN_ROLES, RolesEnum.CREATOR],
      },
    ],
  },
  {
    label: 'Редактировать статью',
    path: '/articles/edit/:id',
    key: '/articles/edit',
    showInMenu: false,
    component: <EditArticlePage />,
    allowedRoles: [...ADMIN_ROLES, RolesEnum.CREATOR],
  },
  {
    label: 'Пользователи',
    path: '/users',
    key: '/users',
    component: <Home />,
    icon: <TeamOutlined />,
    showInMenu: true,
    allowedRoles: ADMIN_ROLES,
    children: [
      {
        label: 'Администраторы',
        path: '/users/admins',
        key: '/users/admins',
        component: <AdminsPage />,
        allowedRoles: [RolesEnum.SUPER_ADMIN],
      },
      {
        label: 'Креаторы',
        path: '/users/creators',
        key: '/users/creators',
        component: <CreatorsPage />,
        allowedRoles: ADMIN_ROLES,
      },
    ],
  },
  {
    label: 'Настройки',
    path: '/settings',
    key: '/settings',
    component: <Home />,
    icon: <SettingOutlined />,
    showInMenu: true,
    allowedRoles: [...ADMIN_ROLES, RolesEnum.CREATOR],
    children: [
      {
        label: 'Управление баннером',
        path: '/settings/banner',
        key: '/settings/banner',
        component: <BannerSettingsPage />,
        allowedRoles: ADMIN_ROLES,
      },
      {
        label: 'Категории',
        path: '/settings/categories',
        key: '/settings/categories',
        component: <Categories />,
        allowedRoles: ADMIN_ROLES,
      },
      {
        label: 'Комьюнити темы',
        path: '/settings/community-topics',
        key: '/settings/community-topics',
        component: <CommunityTopicsPage />,
        allowedRoles: ADMIN_ROLES,
      },
      {
        label: 'Настройки аккаунта',
        path: '/settings/account',
        key: '/settings/account',
        component: <AccountPage />,
        allowedRoles: [...ADMIN_ROLES, RolesEnum.CREATOR],
      },
    ],
  },
];

interface IAppRouter {
  isLoading: boolean;
}

const AppRouter: FC<IAppRouter> = ({ isLoading }) => {
  const { role } = useAppSelector(state => state.authReducer);
  const filteredRoutes = filterRoutesByRole(routesConfig, role);
  const navigate = useNavigate();

  useEffect(() => {
    if (role === RolesEnum.CREATOR) {
      navigate('articles/all');
    }
  }, [role]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {role === RolesEnum.PUBLIC && !isLoading && <Route element={<AuthForm />} path="*" />}

        {role !== RolesEnum.PUBLIC &&
          filteredRoutes.map(route => {
            if (route.children && Array.isArray(route.children)) {
              return route.children.map(r => <Route element={r.component} path={r.path} key={r.path} />);
            }
            return <Route element={route.component} path={route.path} key={route.path} />;
          })}
        <Route element={<Navigate to="/" />} path="*" />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
