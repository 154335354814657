import CreatorsTable from '../../components/tables/creators.table';
import CustomTitle from '../../components/ui/custom-title';

const CreatorsPage = () => {
  return (
    <>
      <CustomTitle>Креаторы</CustomTitle>
      <CreatorsTable />
    </>
  );
};

export default CreatorsPage;
