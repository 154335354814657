import React from 'react';
import CustomTitle from '../../components/ui/custom-title';
import BannerTable from '../../components/tables/banner.table';

const BannerSettingsPage = () => {
  return (
    <>
      <CustomTitle>Настройки баннера</CustomTitle>
      <BannerTable />
    </>
  );
};

export default BannerSettingsPage;
