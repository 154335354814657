import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from './slices/auth/AuthSlice';
import { api } from './services';

const rootReducer = combineReducers({
  authReducer,
  [api.reducerPath]: api.reducer,
});
export const setupStore = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof setupStore.dispatch;
setupListeners(setupStore.dispatch);
