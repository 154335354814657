import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { logout, setUserByLogin } from '../slices/auth/AuthSlice';
import { ILogin } from './auth';
import { Mutex } from 'async-mutex';
import { API_URL } from '../../core/config';
import { getUserDataFromToken } from '../../core/utils';

const mutex = new Mutex();
export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { endpoint }) => {
    if (localStorage.getItem('accessToken') && endpoint !== 'validateToken' && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
    }
    return headers;
  },
});

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  const refreshToken = localStorage.getItem('refreshToken');

  if (
    refreshToken &&
    ((result.error && result.error.status === 401) || (result.error && result.error.status === 406))
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = (await baseQuery(
          {
            url: '/auth/refresh-token',
            method: 'POST',
            body: {
              refreshToken: refreshToken,
            },
          },
          api,
          extraOptions
        )) as { data: ILogin };

        if (refreshResult.data) {
          const newAccessToken = refreshResult.data.accessToken;
          const newRefreshToken = refreshResult.data.refreshToken;

          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('refreshToken', newRefreshToken);

          api.dispatch(setUserByLogin(getUserDataFromToken(refreshResult.data as ILogin)));

          if (typeof args === 'string') {
            args = {
              url: args,
              method: 'GET',
              headers: {
                Authorization: `Bearer ${newAccessToken}`,
              },
            };
          } else {
            args.headers = {
              ...args.headers,
              Authorization: `Bearer ${newAccessToken}`,
            };
          }

          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'auth',
    'categories',
    'community-topics',
    'ads',
    'current-user',
    'admins',
    'creators',
    'articles',
    'article',
    'banner',
  ],
  endpoints: () => ({}),
});
