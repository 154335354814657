import { api } from '../index';
import {
  TCategory,
  TCreateAdmin,
  TCreateCreator,
  TGetCreatorsParams,
  TPagination,
  TPaginationDataRes,
  TSuccessRes,
  TUpdateAdmin,
  TUpdateCreator,
  TUser,
  TUserCreator,
} from '../../../core/types';

export const usersApi = api.injectEndpoints({
  endpoints: builder => ({
    getAdmins: builder.query<TUser[], void>({
      query: () => ({
        url: `/users/admins/many`,
      }),
      providesTags: ['admins'],
    }),
    createAdmin: builder.mutation<TSuccessRes, TCreateAdmin>({
      query: body => ({
        url: `/users/admins/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['admins'],
    }),
    updateAdmin: builder.mutation<TSuccessRes, { id: number; body: TUpdateAdmin }>({
      query: ({ id, body }) => ({
        url: `/users/admins/update/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['admins'],
    }),
    getCreators: builder.query<TPaginationDataRes<TUserCreator>, { params?: TGetCreatorsParams }>({
      query: ({ params }) => ({
        url: `/users/creators/many/`,
        params: params,
      }),
      providesTags: ['creators'],
    }),
    createCreator: builder.mutation<TSuccessRes, TCreateCreator>({
      query: body => ({
        url: `/users/creators/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['creators', 'current-user'],
    }),
    updateCreator: builder.mutation<TSuccessRes, { id: number; body: TUpdateCreator }>({
      query: ({ id, body }) => ({
        url: `/users/creators/update/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['creators', 'current-user'],
    }),
    raiseUpCreator: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/users/creators/raise-up/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['creators'],
    }),
    deleteUser: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/users/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['creators', 'admins'],
    }),
    approveUser: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/users/approve/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['creators'],
    }),
    blockUser: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/users/block/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['creators'],
    }),
    unblockUser: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/users/unblock/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['creators'],
    }),
    changeUserPassword: builder.mutation<
      TSuccessRes,
      { id: number; body: { oldPassword?: string; newPassword: string } }
    >({
      query: ({ id, body }) => ({
        url: `/users/change-password/${id}`,
        method: 'POST',
        body: body,
      }),
    }),
    getCurrentUser: builder.query<TUser, void>({
      query: () => ({
        url: `/users/current/`,
      }),
      providesTags: ['current-user'],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useCreateAdminMutation,
  useUpdateAdminMutation,
  useGetCreatorsQuery,
  useLazyGetCreatorsQuery,
  useCreateCreatorMutation,
  useUpdateCreatorMutation,
  useRaiseUpCreatorMutation,
  useApproveUserMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useDeleteUserMutation,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useChangeUserPasswordMutation,
} = usersApi;
