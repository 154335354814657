import { Button, Dropdown, MenuProps, Modal, Table, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import {
  useDeleteBannerItemMutation,
  useGetBannerItemsQuery,
  useRaiseUpBannerItemMutation,
} from '../../store/services/settings';
import { ColumnsType } from 'antd/es/table';
import { ArticleStatusEnum, TArticle, TBannerItem, TCommunityTopic } from '../../core/types';
import { SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import BannerItemsFormModal from '../ modals/banner-items-form.modal';
import UploadedImage from '../ui/uploaded-image.ui';
import dayjs from 'dayjs';

const { confirm } = Modal;

const BannerTable = () => {
  const bannerItems = useGetBannerItemsQuery();
  const [raiseUpBannerItem] = useRaiseUpBannerItemMutation();
  const [deleteBannerItem] = useDeleteBannerItemMutation();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);

  const bannerItemsData = bannerItems.data?.map((item, index) => ({ ...item, position: index + 1 }));

  const columns: ColumnsType = [
    {
      title: 'Позиция',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
      className: 'w-[50px]',
    },
    {
      title: 'Статья',
      dataIndex: 'article',
      key: 'article',
      align: 'center',
      // className: 'w-[80px]',
      render: (data: TArticle) => {
        return (
          <div className={'flex gap-[10px] items-center'}>
            {data?.cardImage && (
              <div className={'max-w-[80px]'}>
                <UploadedImage data={data.cardImage} preview={false} className={'aspect-[48/36]'} />
              </div>
            )}
            <h4>{data.title}</h4>
          </div>
        );
      },
    },
    {
      title: 'Статус',
      dataIndex: '',
      key: 'publicationStatus',
      align: 'center',
      className: 'min-w-[150px]',
      render: (_, data: TBannerItem) => {
        const now = dayjs();
        const publicationDate = dayjs(data.article.publicationDate);
        if (now.isAfter(publicationDate) && data.article.status === ArticleStatusEnum.APPROVED) {
          return <Tag color={'green'}>Опубликовано</Tag>;
        }
        if (now.isBefore(publicationDate) && data.article.status === ArticleStatusEnum.APPROVED) {
          return (
            <Tooltip title={'Проверьте статус публикации или дату публикации'} color={'orange'}>
              <Tag color={'orange'}>
                Не опубликовано <QuestionCircleOutlined />
              </Tag>
            </Tooltip>
          );
        }
        return '-';
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      className: 'w-[50px]',
      render: (_, data: TCommunityTopic) => {
        return (
          <Dropdown menu={{ items: renderDropDownItems(data.id) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [
      {
        key: 'raiseUp',
        onClick: () => {
          confirm({
            title: 'Поднять статью в топ?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Поднять',
            onOk() {
              raiseUpBannerItem({ id });
            },
          });
        },
        label: 'Поднять в топ',
      },
      {
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить статью из баннера?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Удалить',
            onOk() {
              deleteBannerItem({ id });
            },
          });
        },
        label: 'Удалить',
        danger: true,
      },
    ];

    return items;
  };

  return (
    <>
      <div className={'pt-[15px] pb-[25px]'}>
        <Button type="primary" size={'large'} onClick={() => setIsFormModalOpen(true)}>
          Добавить статью
        </Button>
      </div>
      <Table scroll={{ x: true }} pagination={false} dataSource={bannerItemsData} columns={columns} rowKey="id" />
      <BannerItemsFormModal open={isFormModalOpen} setClose={() => setIsFormModalOpen(false)} />
    </>
  );
};

export default BannerTable;
