import { Button, Layout, Menu, MenuProps } from 'antd';
import AppRouter, { routesConfig } from './app-router';
import { useAppDispatch, useAppSelector } from '../core/hooks/redux';
import { useLazyValidateTokenQuery } from '../store/services/auth';
import { useEffect, useState } from 'react';
import { logout, setUser } from '../store/slices/auth/AuthSlice';
import Loader from './loader';
import { RolesEnum } from '../core/types';
import clsx from 'clsx';
import { MenuFoldOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../store/services/users';
import { skipToken } from '@reduxjs/toolkit/query';
import User from './ui/user';

const { Header, Content, Footer, Sider } = Layout;

const BaseLayout = () => {
  const location = useLocation();
  const { role, user, accessToken } = useAppSelector(state => state.authReducer);
  const [fetch, { isLoading, isSuccess, isError }] = useLazyValidateTokenQuery();
  const getCurrentUser = useGetCurrentUserQuery(role === RolesEnum.PUBLIC ? skipToken : undefined);
  const isTouchable = 'ontouchstart' in window || navigator.maxTouchPoints;
  const [siderColapsed, setSiderColapsed] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([location.pathname]);
  const dispatch = useAppDispatch();
  const isPublicRole = role === RolesEnum.PUBLIC;
  const navigate = useNavigate();

  useEffect(() => {
    fetch(accessToken as string);

    const isSiderColapsed = localStorage.getItem('siderCollapsed');

    if (!isTouchable && isSiderColapsed) {
      setSiderColapsed(!!Number(isSiderColapsed));
    } else {
      setSiderColapsed(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getCurrentUser.refetch();
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser());
    }
  }, [isLoading]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location.pathname]);

  const handleCollapse = (collapsed: boolean) => {
    localStorage.setItem('siderCollapsed', collapsed ? '1' : '0');
    setSiderColapsed(collapsed);
  };

  const handleMenuItemClick: MenuProps['onClick'] = e => {
    if (e.key === 'logout') {
      return dispatch(logout());
    }
    navigate(e.key);
    setSelectedKeys([e.key]);
    if (isTouchable) {
      handleCollapse(true);
    }
  };
  const isRoleAllowed = (allowedRoles: RolesEnum[] = [], userRoles: RolesEnum) => {
    return allowedRoles.some(role => userRoles.includes(role));
  };

  const menuItems = routesConfig
    .filter(route => route.showInMenu === true)
    .filter(route => isRoleAllowed(route.allowedRoles, role)) // Фильтрация по ролям
    .map(route => {
      const { label, path, key, icon, children } = route;
      const filteredChildren = children
        ?.filter(child => isRoleAllowed(child.allowedRoles, role)) // Фильтрация дочерних элементов по ролям
        .map(child => {
          const { label, path, key, icon } = child;
          return { label, path, key, icon };
        });

      return {
        label,
        path,
        key,
        icon,
        children: filteredChildren,
      };
    });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout hasSider className={'min-h-[100vh]'} style={!siderColapsed && isTouchable ? { overflow: 'hidden' } : {}}>
      {!isPublicRole && (
        <>
          {isTouchable && !siderColapsed && (
            <div
              onClick={() => setSiderColapsed(true)}
              className={'fixed w-[100vw] h-[100vh] bg-black opacity-40 z-[60]'}
            ></div>
          )}
          <Sider
            onCollapse={handleCollapse}
            collapsed={!isTouchable && siderColapsed}
            style={{
              position: isTouchable ? 'fixed' : 'fixed',
              height: '100vh',
              left: `${siderColapsed && isTouchable ? '-280px' : '0'}`,
              zIndex: 999,
            }}
            width={280}
          >
            <Button
              onClick={() => handleCollapse(!siderColapsed)}
              className={clsx({
                'absolute top-2 rounded-0': true,
                'right-2': !siderColapsed,
                'right-[-50px]': siderColapsed,
              })}
              size={'large'}
              icon={
                <MenuFoldOutlined
                  style={
                    siderColapsed
                      ? { rotate: '0deg', transition: 'rotate 0.3s' }
                      : { rotate: '180deg', transition: 'rotate 0.3s' }
                  }
                />
              }
            />
            <div
              className={clsx('h-[62px] py-[8px] px-[25px] flex ', {
                'justify-center': siderColapsed,
                'justify-start': !siderColapsed,
              })}
            >
              <span className={'text-white text-[40px] leading-[normal] font-bold'}>R{!siderColapsed && 'AFL'}</span>
            </div>
            <Menu
              onClick={handleMenuItemClick}
              style={{
                fontSize: '16px',
                maxHeight: '100vh',
                overflowY: 'auto',
                paddingTop: '30px',
                paddingBottom: '70px',
              }}
              // theme="dark"
              mode="inline"
              selectedKeys={selectedKeys}
              items={menuItems}
            />
          </Sider>
        </>
      )}
      <Layout id={'content-layout'}>
        {!isPublicRole && (
          <Header className={'sticky top-0 z-50 border-b-2 border-[#1f1f1f] pr-[15px] flex justify-end items-center'}>
            {getCurrentUser.data && <User user={getCurrentUser.data} />}
          </Header>
        )}
        <Content
          style={{ transition: 'margin-left 0.3s' }}
          className={clsx({
            'bg-white m-3 p-6 rounded-2xl duration-500': !isPublicRole,
            'ml-[295px]': !isPublicRole && !isTouchable && !siderColapsed,
            'ml-[90px]': !isPublicRole && !isTouchable && siderColapsed,
          })}
        >
          <AppRouter isLoading={isLoading} />
        </Content>
        <Footer
          className={clsx({
            'text-center': true,
            'bg-white m-3 p-3 rounded-2xl': role !== RolesEnum.PUBLIC,
            'ml-[295px]': !isPublicRole && !isTouchable && !siderColapsed,
            'ml-[90px]': !isPublicRole && !isTouchable && siderColapsed,
          })}
        >
          © 2024 Created by{' '}
          <a href="https://t.me/alex_romanchuk" target={'_blank'} rel={'noreferrer'}>
            @Aks_RK
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
