import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useRaiseUpCategoryMutation,
} from '../../store/services/categories';
import { Button, Dropdown, MenuProps, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TCategory } from '../../core/types';
import { SettingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';
import CategoriesFormModal from '../ modals/categories-form.modal';

const { confirm } = Modal;

const CategoriesTable = () => {
  const categories = useGetCategoriesQuery();
  const [deleteCategory, deleteCategoryStates] = useDeleteCategoryMutation();
  const [raiseUpCategory, raiseUpCategoryStates] = useRaiseUpCategoryMutation();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<TCategory | undefined>(undefined);

  useEffect(() => {
    if (deleteCategoryStates.isSuccess) {
      toast.success('Категория удалена');
    }
  }, [deleteCategoryStates.isSuccess]);
  useEffect(() => {
    if (deleteCategoryStates.isError) {
      toast.error(getExceptionMessage(deleteCategoryStates.error));
    }
  }, [deleteCategoryStates.isError]);

  const columns: ColumnsType = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      className: 'min-w-[400px]',
      key: 'description',
    },
    {
      title: 'URL',
      dataIndex: 'pathName',
      key: 'pathName',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      render: (_, data: TCategory) => {
        if (data.isMainCategory) {
          return null;
        }

        return (
          <Dropdown menu={{ items: renderDropDownItems(data.id) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setSelectedCategory(undefined);
    setIsFormModalOpen(false);
  };

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        onClick: () => {
          setSelectedCategory(categories.data?.find(el => el.id === id));
          setIsFormModalOpen(true);
        },
        label: 'Редактировать',
      },
      {
        key: 'raiseUp',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите поднять категорию в топ?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Поднять',
            onOk() {
              raiseUpCategory({ id });
            },
          });
        },
        label: 'Поднять в топ',
      },
      {
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить категорию?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Удалить',
            onOk() {
              deleteCategory({ id });
            },
          });
        },
        label: 'Удалить',
        danger: true,
      },
    ];

    return items;
    // return items.map((el, index) => ({ ...el, key: 'drop-down-item' + index + 1 })) as MenuProps['items'];
  };

  return (
    <>
      <div className={'pt-[15px] pb-[25px]'}>
        <Button type="primary" size={'large'} onClick={() => setIsFormModalOpen(true)}>
          Создать
        </Button>
      </div>
      <Table scroll={{ x: true }} pagination={false} dataSource={categories.data} columns={columns} rowKey="id" />
      <CategoriesFormModal open={isFormModalOpen} setClose={handleCloseModal} data={selectedCategory} />
    </>
  );
};

export default CategoriesTable;
