import { useGetCurrentUserQuery } from '../store/services/users';
import CustomTitle from '../components/ui/custom-title';
import { useAppSelector } from '../core/hooks/redux';
import { RolesEnum } from '../core/types';
import { skipToken } from '@reduxjs/toolkit/query';

const Home = () => {
  const { role } = useAppSelector(state => state.authReducer);
  const { data } = useGetCurrentUserQuery(role === RolesEnum.PUBLIC ? skipToken : undefined);

  return (
    <>
      <CustomTitle>
        С возвращением <br />
        {data && data.username}!
      </CustomTitle>
    </>
  );
};

export default Home;
