import { api } from '../index';
import { TImage, TSuccessRes } from '../../../core/types';

export const FilesApi = api.injectEndpoints({
  endpoints: builder => ({
    uploadImage: builder.mutation<TImage, FormData>({
      query: formData => ({
        url: `/files/images`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteImage: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/files/images/remove-one/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteImageByStorageName: builder.mutation<TSuccessRes, { name: string }>({
      query: ({ name }) => ({
        url: `/files/images/remove-by-storage-name/${name}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useUploadImageMutation, useDeleteImageMutation, useDeleteImageByStorageNameMutation } = FilesApi;
