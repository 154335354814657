import { api } from '../index';
import { TCategory, TSuccessRes } from '../../../core/types';
import { TCreateCategoryForm, TUpdateCategoryForm } from '../../../core/types/forms/categories';

export const categoriesApi = api.injectEndpoints({
  endpoints: builder => ({
    createCategory: builder.mutation<TSuccessRes, TCreateCategoryForm>({
      query: body => ({
        url: `/categories/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['categories'],
    }),
    raiseUpCategory: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/categories/raise-up/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['categories'],
    }),
    getCategories: builder.query<TCategory[], void>({
      query: () => ({
        url: `/categories`,
      }),
      providesTags: ['categories'],
    }),
    updateCategory: builder.mutation<TSuccessRes, { id: number; body: TUpdateCategoryForm }>({
      query: ({ id, body }) => ({
        url: `/categories/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['categories'],
    }),
    deleteCategory: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['categories'],
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useRaiseUpCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
