import React, { FC, UIEvent, useCallback, useEffect, useState } from 'react';
import {
  TArticle,
  TFormModal,
  TGetArticlesParams,
  TGetCreatorsParams,
  TUserCreator,
  UserStatusEnum,
} from '../../core/types';
import { Avatar, Form, Modal, Select, Spin } from 'antd';
import { toast } from 'react-toastify';
import { getExceptionMessage, getImageUrl } from '../../core/utils';
import { useAddBannerItemMutation } from '../../store/services/settings';
import FormItem from 'antd/es/form/FormItem';
import { useLazyGetArticlesQuery } from '../../store/services/articles';
import debounce from 'lodash.debounce';
import { API_URL } from '../../core/config';
import { FileTextOutlined, UserOutlined } from '@ant-design/icons';

const BannerItemsFormModal: FC<TFormModal> = ({ open, setClose }) => {
  const [addBannerItem, addBannerItemStates] = useAddBannerItemMutation();
  const [form] = Form.useForm();
  const [fetchArticles, fetchArticlesStates] = useLazyGetArticlesQuery();
  const [articles, setArticles] = useState<TArticle[] | []>([]);
  const [articlesParams, setArticlesParams] = useState<TGetArticlesParams>({
    page: 1,
    limit: 7,
    isPublished: true,
  });

  useEffect(() => {
    if (addBannerItemStates.isSuccess) {
      toast.success('Статья добавлена в баннер');
      handleCloseModal();
    }
  }, [addBannerItemStates.isSuccess]);

  useEffect(() => {
    if (addBannerItemStates.isError) {
      toast.error(getExceptionMessage(addBannerItemStates.error));
    }
  }, [addBannerItemStates.isError]);

  const fetchMoreArticles = useCallback(() => {
    fetchArticles({ params: articlesParams });
  }, [fetchArticles, articlesParams]);

  useEffect(() => {
    fetchMoreArticles();
  }, [articlesParams, fetchMoreArticles]);

  useEffect(() => {
    if (fetchArticlesStates.data?.data) {
      const data = fetchArticlesStates.data?.data;
      setArticles(prevState => [...prevState, ...(data || [])]);
    }
  }, [fetchArticlesStates.data]);

  const handleScrollArticles = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !fetchArticlesStates.isLoading &&
      fetchArticlesStates.data?.hasNextPage
    ) {
      setArticlesParams(prevState => ({ ...prevState, page: (prevState.page || 1) + 1 }));
    }
  };

  const handleSearchArticle = debounce(value => {
    setArticlesParams(prevState => ({ ...prevState, page: 1, title: value }));
    setArticles([]);
  }, 500);
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };
  const handleSubmit = async () => {
    if (addBannerItemStates.isLoading) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      addBannerItem({ body: { articleId: formData.articleId } });
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      title={`Добавить статью в баннер`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmit}
      confirmLoading={addBannerItemStates.isLoading}
      onCancel={handleCloseModal}
      okText={'Добавить'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        <FormItem label={'Статья'} name={'articleId'} rules={[{ required: true, message: 'Вы не выбрали статью!' }]}>
          <Select
            allowClear
            showSearch
            size={'large'}
            onSearch={handleSearchArticle}
            notFoundContent={fetchArticlesStates.isLoading ? <Spin size="small" /> : null}
            filterOption={false}
            placeholder="Статья"
            onPopupScroll={handleScrollArticles}
            dropdownRender={menu => (
              <>
                <Select.OptGroup label={'Креатор'} />
                {menu}
                {fetchArticlesStates.isLoading && <Spin size="small" />}
              </>
            )}
          >
            {articles.map(article => (
              <Select.Option key={article.id} value={article.id} label={article.title}>
                <div className={'flex gap-3 items-center'}>
                  <Avatar
                    shape="square"
                    src={getImageUrl(article?.cardImage)}
                    icon={<FileTextOutlined />}
                    className={'flex-shrink-0'}
                  />
                  <span className={'pr-2'}>{article.title}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default BannerItemsFormModal;
