import SunEditor from 'suneditor-react';
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';
import { SunEditorOptions } from 'suneditor/src/options';
import { API_URL } from '../core/config';
import { useUploadImageMutation } from '../store/services/files';
import { UploadBeforeHandler } from 'suneditor-react/dist/types/upload';
import { useEffect, useRef, useState } from 'react';
import SunEditorCore from 'suneditor/src/lib/core';
import { toast } from 'react-toastify';
import { getExceptionMessage, getImageUrl } from '../core/utils';

type IEditorProps = {
  initialContent: string;
  name?: string;
  setContent: (value: string) => void;
  props?: SunEditorReactProps;
};

export default function Editor({ initialContent = '', name = 'editor', setContent, props }: IEditorProps) {
  const editorRef = useRef<SunEditorCore | null>(null);
  const [uploadImage, uploadImageStates] = useUploadImageMutation();
  const [body, setBody] = useState<string>('');

  useEffect(() => {
    setContent(body);
  }, [body]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.core.context.element.wysiwyg.innerHTML = initialContent;
    }
  }, [initialContent]);

  useEffect(() => {
    if (uploadImageStates.isError) {
      toast.error(getExceptionMessage(uploadImageStates.error));
    }
  }, [uploadImageStates]);

  const options: SunEditorOptions = {
    height: '70vh',
    className: 'seWrp',
    stickyToolbar: 70,
    callBackSave: () => '',
    formats: [
      {
        tag: 'h3',
        name: 'Заголовок 3',
        command: 'free',
        class: 'articleHeading articleHeading_h3',
      },
      {
        tag: 'h4',
        name: 'Заголовок 4',
        command: 'free',
        class: 'articleHeading articleHeading_h4',
      },
      {
        tag: 'p',
        name: 'Текст',
        command: 'free',
        class: 'articleText',
      },
      {
        tag: 'blockquote',
        name: 'Цитата',
        command: 'range',
        class: 'articleBlockquote',
      },
      {
        tag: 'div',
        name: 'Блок с фоном',
        command: 'range',
        class: 'articleBgBlock articleText',
      },
      {
        tag: 'pre',
        name: 'Код',
        command: 'range',
        class: '',
      },
    ],
    font: ['CoFoKaK', 'Suisse Intl'],
    videoResizing: false,
    videoFileInput: false,
    imageWidth: '650px',
    buttonList: [
      ['undo', 'redo'],
      ['removeFormat'],
      ['formatBlock'],
      ['font', 'fontSize'],
      ['bold', 'underline', 'italic', 'strike'],
      ['fontColor', 'hiliteColor'],
      ['outdent', 'indent', 'align', 'list'],
      ['link', 'image', 'video'],
      ['showBlocks'],
    ],
  };

  const handleImageUploadBefore = (files: File[], info: any, uploadHandler: UploadBeforeHandler) => {
    const formData = new FormData();
    formData.append('image', files[0]);

    uploadImage(formData)
      .unwrap()
      .then(response => {
        const res = {
          result: [
            {
              url: getImageUrl(response) as string,
              size: files[0].size,
              name: String(response.id),
            },
          ],
        };
        uploadHandler(res);
      })
      .catch(error => {
        uploadHandler();
      });
  };

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editorRef.current = sunEditor;
  };

  const handleChangeContent = (value: string) => {
    if (editorRef.current) {
      const html = editorRef.current.core.context.element.wysiwyg.innerHTML;
      setContent(html);
    }
  };

  return (
    <>
      <SunEditor
        {...props}
        setDefaultStyle={'font-family: Suisse Intl;'}
        getSunEditorInstance={getSunEditorInstance}
        name={name}
        lang={'ru'}
        setOptions={options}
        onImageUploadBefore={handleImageUploadBefore as any}
        onChange={handleChangeContent}
      />
    </>
  );
}
