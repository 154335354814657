import React from 'react';
import CustomTitle from '../../components/ui/custom-title';
import ArticlesTable from '../../components/tables/articles/articles.table';

const AllArticlesPage = () => {
  return (
    <>
      <CustomTitle>Все статьи</CustomTitle>
      <ArticlesTable />
    </>
  );
};

export default AllArticlesPage;
