import { Button, Dropdown, MenuProps, Modal, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { RolesEnum, TAd, TCommunityTopic, TImage, TPagination } from '../../core/types';
import { SettingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';
import { useDeleteAdMutation, useGetAdsQuery } from '../../store/services/ads';
import { DEFAULT_PAGE, DEFAULT_PAGE_LIMIT, RU_DATE } from '../../core/config';
import AdsFormModal from '../ modals/ads-form.modal';
import UploadedImage from '../ui/uploaded-image.ui';
import dayjs from 'dayjs';
import { useAppSelector } from '../../core/hooks/redux';

const { confirm } = Modal;

const AdsTable = () => {
  const { role } = useAppSelector(state => state.authReducer);
  const isSuperAdmin = role === RolesEnum.SUPER_ADMIN;
  const [params, setParams] = useState<TPagination>({
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_LIMIT,
  });
  const ads = useGetAdsQuery({ params });
  const [deleteAd, deleteAdStates] = useDeleteAdMutation();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [selectedAd, setSelectedAd] = useState<TAd | undefined>(undefined);

  useEffect(() => {
    if (deleteAdStates.isSuccess) {
      toast.success('Рекламный блок удален');
    }
  }, [deleteAdStates.isSuccess]);
  useEffect(() => {
    if (deleteAdStates.isError) {
      toast.error(getExceptionMessage(deleteAdStates.error));
    }
  }, [deleteAdStates.isError]);

  const columns: ColumnsType = [
    {
      title: 'Фото',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      className: 'w-[150px]',
      render: (data: TImage) => {
        return (
          <div className={'w-[150px]'}>
            <UploadedImage data={data} preview={false} className={'aspect-[49/25]'} />
          </div>
        );
      },
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      className: 'min-w-[150px]',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      className: 'min-w-[210px] max-w-[350px]',
    },
    {
      title: 'Ссылка',
      dataIndex: 'url',
      key: 'url',
      align: 'center',
      render: (url: string) => {
        return (
          <a href={url} target={'_blank'} rel="noopener noreferrer" className={'text-blue-600'}>
            Перейти
          </a>
        );
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      className: 'w-[210px]',
      render: (_, data: TAd) => {
        const now = dayjs();
        const startDate = dayjs(data.startDate);
        const endDate = dayjs(data.endDate).endOf('day');

        let color, text;
        if (now.isBefore(startDate)) {
          color = 'orange';
          text = 'Ожидает';
        } else if (now.isAfter(endDate)) {
          color = 'red';
          text = 'Отключена';
        } else {
          color = 'green';
          text = 'Опубликовано';
        }

        return (
          <Tag color={color} className={'text-[12px] py-[3px] px-[10px]'}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Доступна с / до',
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
      className: 'min-w-[210px]',
      render: (_, data: TAd) => {
        const startDate = dayjs(data.startDate).format(RU_DATE);
        const endDate = dayjs(data.endDate).format(RU_DATE);
        return (
          <div>
            {startDate} / {endDate}
          </div>
        );
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      className: 'w-[50px]',
      render: (_, data: TCommunityTopic) => {
        return (
          <Dropdown menu={{ items: renderDropDownItems(data.id) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setSelectedAd(undefined);
    setIsFormModalOpen(false);
  };

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        onClick: () => {
          const ad = ads.data?.data?.find(el => el.id === id);
          setSelectedAd(ad);
          setIsFormModalOpen(true);
        },
        label: 'Редактировать',
      },
    ];

    if (isSuperAdmin) {
      items.push({
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить рекламный блок?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Удалить',
            onOk() {
              deleteAd({ id });
            },
          });
        },
        label: 'Удалить',
        danger: true,
      });
    }

    return items;
  };

  const handlePaginationChange = (page: number, limit: number) => {
    setParams({
      page,
      limit,
    });
  };

  return (
    <>
      <div className={'pt-[15px] pb-[25px]'}>
        {isSuperAdmin && (
          <Button type="primary" size={'large'} onClick={() => setIsFormModalOpen(true)}>
            Создать
          </Button>
        )}
      </div>
      <Table
        scroll={{ x: true }}
        pagination={{
          current: params.page,
          pageSize: params.limit,
          total: ads.data?.totalCount,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '25', '50', '100'],
        }}
        dataSource={ads.data?.data}
        columns={columns}
        rowKey="id"
      />
      <AdsFormModal open={isFormModalOpen} setClose={handleCloseModal} data={selectedAd} />
    </>
  );
};

export default AdsTable;
