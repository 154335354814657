import { FC, UIEvent, useCallback, useEffect, useState } from 'react';
import Editor from '../sun-editor';
import { Avatar, Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Spin, Tag } from 'antd';
import { ADMIN_ROLES, API_URL, RU_DATE } from '../../core/config';
import { UserOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useGetCategoriesQuery } from '../../store/services/categories';
import { useLazyGetCreatorsQuery } from '../../store/services/users';
import {
  ArticleStatusEnum,
  TArticle,
  TGetArticlesParams,
  TGetCreatorsParams,
  TImage,
  TUserCreator,
  UserStatusEnum,
} from '../../core/types';
import debounce from 'lodash.debounce';
import ImageUpload from '../image-upload';
import FormItem from 'antd/es/form/FormItem';
import {
  getExceptionMessage,
  getImageUrl,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
} from '../../core/utils';
import {
  useCreateArticleMutation,
  useLazyGetArticlesQuery,
  useUpdateArticleMutation,
} from '../../store/services/articles';
import { useAppSelector } from '../../core/hooks/redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type TArticleForm = {
  data?: TArticle;
};

const ArticleForm: FC<TArticleForm> = ({ data }) => {
  const { role, user } = useAppSelector(state => state.authReducer);
  const navigate = useNavigate();
  const [createArticle, createArticleStates] = useCreateArticleMutation();
  const [updateArticle, updateArticleStates] = useUpdateArticleMutation();
  const [form] = Form.useForm();
  const categories = useGetCategoriesQuery();
  const [creators, setCreators] = useState<TUserCreator[] | []>([]);
  const [fetchCreators, fetchCreatorsStates] = useLazyGetCreatorsQuery();
  const [creatorsParams, setCreatorsParams] = useState<TGetCreatorsParams>({
    status: UserStatusEnum.APPROVED,
    page: 1,
    limit: 10,
  });
  const [articles, setArticles] = useState<TArticle[] | []>([]);
  const [fetchArticles, fetchArticlesStates] = useLazyGetArticlesQuery();
  const [articlesParams, setArticlesParams] = useState<TGetArticlesParams>({
    status: ArticleStatusEnum.APPROVED,
    excludeMainCategory: true,
    page: 1,
    limit: 10,
  });
  const [selectedArticles, setSelectedArticles] = useState<TArticle[]>([]);
  const [content, setContent] = useState<string>('');
  const isAdmin = ADMIN_ROLES.includes(role);
  const selectedCategoryId = Form.useWatch('categoryId', form);
  const selectedCreatorId = Form.useWatch('creatorId', form);
  const isMainCategory = categories.data?.find(cat => cat.id === selectedCategoryId)?.isMainCategory;

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      form.setFieldValue('cardImageId', data.cardImage?.id || undefined);
      form.setFieldValue('cardImageId', data.articleImage?.id || undefined);
      setContent(data.content);
      setSelectedArticles(data.readMore);
    }
    if (data && isAdmin) {
      form.setFieldValue('categoryId', data.category?.id || undefined);
      if (data.creatorUser) {
        setCreators(prevState => [...prevState, data.creatorUser as TUserCreator]);
      }
      form.setFieldValue('creatorId', data.creatorUser?.id || undefined);
    }
  }, [data]);

  useEffect(() => {
    if (createArticleStates.isSuccess) {
      toast.success('Статья создана');
      navigate('/articles/all');
    }
  }, [createArticleStates.isSuccess]);

  useEffect(() => {
    if (updateArticleStates.isSuccess) {
      toast.success('Статья изменена');
      navigate('/articles/all');
    }
  }, [updateArticleStates.isSuccess]);

  useEffect(() => {
    if (updateArticleStates.isError) {
      toast.error(getExceptionMessage(updateArticleStates.error));
    }
    if (createArticleStates.isError) {
      toast.error(getExceptionMessage(createArticleStates.error));
    }
  }, [updateArticleStates.isError, createArticleStates.isError]);

  const onFinish = async () => {
    const formData = form.getFieldsValue();

    if (!content || content.trim().length === 0) {
      return toast.error('Статья не может быть пустой!');
    }

    const body = {
      ...formData,
      content,
      readMore: selectedArticles.map(art => art.id),
    };
    try {
      if (data) {
        return updateArticle({ id: data.id, body: body });
      }
      return createArticle(body);
    } catch (e) {
      /* empty */
    }
  };

  const fetchMoreCreators = useCallback(() => {
    fetchCreators({ params: creatorsParams });
  }, [fetchCreators, creatorsParams]);

  useEffect(() => {
    fetchMoreCreators();
  }, [creatorsParams, fetchMoreCreators]);

  useEffect(() => {
    if (fetchCreatorsStates.data?.data) {
      const newCreators = fetchCreatorsStates.data.data;
      const existingCreatorIds = new Set(creators.map(creator => creator.id));
      const filteredNewCreators = newCreators.filter(creator => !existingCreatorIds.has(creator.id));
      setCreators(prevCreators => [...prevCreators, ...filteredNewCreators]);
    }
  }, [fetchCreatorsStates.data]);

  const handleSearchCreator = debounce(value => {
    setCreatorsParams(prevState => ({ ...prevState, page: 1, username: value }));
    if (data?.creatorUser && isAdmin && selectedCreatorId === data?.creatorUser?.id) {
      return setCreators([data?.creatorUser as TUserCreator]);
    }
    setCreators([]);
  }, 500);
  const handleScrollCreators = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !fetchCreatorsStates.isLoading &&
      fetchCreatorsStates.data?.hasNextPage
    ) {
      setCreatorsParams(prevState => ({ ...prevState, page: (prevState.page || 1) + 1 }));
    }
  };

  const fetchMoreArticles = useCallback(() => {
    fetchArticles({ params: articlesParams });
  }, [fetchArticles, articlesParams]);

  useEffect(() => {
    fetchMoreArticles();
  }, [articlesParams, fetchMoreArticles]);

  useEffect(() => {
    if (fetchArticlesStates.data?.data) {
      const newArticles = data
        ? fetchArticlesStates.data.data.filter(art => art.id !== data.id)
        : fetchArticlesStates.data.data;
      const existingArticlesIds = new Set(articles.map(article => article.id));
      const filteredNewArticles = newArticles.filter(article => !existingArticlesIds.has(article.id));
      setArticles(prevArticles => [...prevArticles, ...filteredNewArticles]);
    }
  }, [fetchArticlesStates.data]);

  const handleSearchArticle = debounce(value => {
    setArticlesParams(prevState => ({ ...prevState, page: 1, title: value }));
    if (selectedArticles) {
      setArticles(selectedArticles);
    }
  }, 500);
  const handleScrollArticles = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !fetchArticlesStates.isLoading &&
      fetchArticlesStates.data?.hasNextPage
    ) {
      setArticlesParams(prevState => ({ ...prevState, page: (prevState.page || 1) + 1 }));
    }
  };

  const handleChangeArticles = (selectedValues: string[]) => {
    const selectedNumValues = selectedValues.map(Number);
    const values = articles.filter(el => selectedNumValues.includes(el.id));
    setSelectedArticles(values);
  };

  const setCardImage = (data: TImage) => {
    form.setFieldValue('cardImageId', data.id);
    form.validateFields();
  };

  const setArticleImage = (data: TImage) => {
    form.setFieldValue('articleImageId', data.id);
    form.validateFields();
  };

  return (
    <div>
      <Form
        form={form}
        className={'max-w-[940px]'}
        size={'large'}
        name="article-form"
        layout={'vertical'}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <FormItem
          label={'Фото карточки'}
          name={'cardImageId'}
          rules={[{ required: true, message: 'Вы не загрузили фото!' }]}
        >
          <ImageUpload
            className={'max-w-[392px] '}
            imageClassName={'aspect-[48/36]'}
            setImageId={setCardImage}
            data={data?.cardImage}
          />
        </FormItem>
        <FormItem
          label={'Фото в теле статьи'}
          name={'articleImageId'}
          rules={[{ required: true, message: 'Вы не загрузили фото!' }]}
        >
          <ImageUpload imageClassName={'aspect-[50/33]'} setImageId={setArticleImage} data={data?.articleImage} />
        </FormItem>
        {isAdmin && (
          <FormItem
            label={'Дата публикации'}
            name={'publicationDate'}
            getValueFromEvent={getValueFormDatePicker}
            getValueProps={getValueFormDatePickerProps}
          >
            <DatePicker className={'w-full'} format={RU_DATE} />
          </FormItem>
        )}
        <Form.Item
          name="title"
          label={'Заголовок'}
          rules={[
            { required: true, message: 'Поле обязательно к заполнению!' },
            {
              min: 3,
              message: 'Минимальная длина 3 знака!',
            },
          ]}
        >
          <TextArea placeholder="Заголовок" />
        </Form.Item>
        <Form.Item
          name="cardDescription"
          label={'Описание (карточка)'}
          rules={[
            { required: true, message: 'Поле обязательно к заполнению!' },
            {
              min: 5,
              message: 'Минимальная длина 5 знаков!',
            },
          ]}
        >
          <TextArea placeholder="Описание (карточка)" />
        </Form.Item>
        <Form.Item
          name="description"
          label={'Описание (статья)'}
          rules={[
            { required: true, message: 'Поле обязательно к заполнению!' },
            {
              min: 5,
              message: 'Минимальная длина 5 знаков!',
            },
          ]}
        >
          <TextArea placeholder="Описание (статья)" />
        </Form.Item>

        {isAdmin && (
          <>
            <Form.Item
              label={'Категория'}
              key={'categoryId'}
              name={'categoryId'}
              rules={[{ required: true, message: 'Вы не выбрали категорию!' }]}
            >
              <Select allowClear>
                <Select.OptGroup label={'Категория'} />
                {categories.data?.map(category => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {isMainCategory && (
              <Form.Item
                label={'Креатор'}
                key={'creatorId'}
                name={'creatorId'}
                rules={[{ required: true, message: 'Вы не выбрали креатора!' }]}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={handleSearchCreator}
                  notFoundContent={fetchCreatorsStates.isLoading ? <Spin size="small" /> : null}
                  filterOption={false}
                  optionLabelProp="label"
                  onPopupScroll={handleScrollCreators}
                  dropdownRender={menu => (
                    <>
                      <Select.OptGroup label={'Креатор'} />
                      {menu}
                      {fetchCreatorsStates.isLoading && <Spin size="small" />}
                    </>
                  )}
                >
                  {creators.map(creator => (
                    <Select.Option key={creator.id} value={creator.id} label={creator.username}>
                      <div className={'flex gap-3 items-center'}>
                        <Avatar src={getImageUrl(creator.image)} icon={<UserOutlined />} className={'flex-shrink-0'} />
                        <span className={'pr-2'}>{creator.username}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        )}
        {isAdmin && !isMainCategory && (
          <Form.Item label={'Читайте также'}>
            <Select
              mode={'tags'}
              size={'large'}
              onSearch={handleSearchArticle}
              filterOption={false}
              value={selectedArticles.map(el => String(el.id))}
              onChange={handleChangeArticles}
              onPopupScroll={handleScrollArticles}
              tagRender={item => (
                <div className={'p-2 rounded-[10px] w-full mx-auto'}>
                  <Tag closable={true} onClose={item.onClose} className={'flex p-1 rounded-[13px] cursor-default'}>
                    {item.label}
                  </Tag>
                </div>
              )}
            >
              {articles.map(article => (
                <Select.Option key={article.id} value={String(article.id)} label={article.title}>
                  <div className={'flex gap-3 items-center'}>
                    <Avatar
                      shape="square"
                      size={48}
                      className={'bg-white text-black'}
                      src={getImageUrl(article?.cardImage)}
                      icon={<UserOutlined />}
                    />
                    <div>
                      <div className={'pr-2 text-[18px] mb-[5px]'}>{article.title}</div>
                      <Tag color={'blue'} className={'text-[10px]'}>
                        {article.category.name}
                      </Tag>
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {isAdmin && (
          <Form.Item name="claps" label={'Хлопки'}>
            <InputNumber className={'w-full'} />
          </Form.Item>
        )}
        <Editor initialContent={data?.content || ''} setContent={setContent} />
        <div className={'flex items-center flex-col flex-wrap mt-[20px]'}>
          {isAdmin && (
            <FormItem className={'!m-0'} name={'disableAds'} valuePropName="checked" initialValue={false}>
              <Checkbox>Отключить показ рекламы</Checkbox>
            </FormItem>
          )}
          {isAdmin && !data && (
            <FormItem name={'confirmAfterCreation'} valuePropName="checked" initialValue={false}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <Checkbox>Установить статус "Одобрено" после создания</Checkbox>
            </FormItem>
          )}
          <Button type="primary" htmlType="submit">
            {!data ? 'Создать' : 'Сохранить'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ArticleForm;
