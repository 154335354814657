import AdminsTable from '../../components/tables/admins.table';
import CustomTitle from '../../components/ui/custom-title';

const AdminsPage = () => {
  return (
    <>
      <CustomTitle>Администраторы</CustomTitle>
      <AdminsTable />
    </>
  );
};

export default AdminsPage;
