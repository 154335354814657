import React, { FC, useEffect, useState } from 'react';
import { Button, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useUploadImageMutation } from '../store/services/files';
import { TImage } from '../core/types';
import UploadedImage from './ui/uploaded-image.ui';
import { getExceptionMessage } from '../core/utils';
import { toast } from 'react-toastify';

type TImageUploadForm = {
  data?: TImage;
  className?: string;
  imageClassName?: string;
  setImageId: (data: TImage) => void;
};
const ImageUpload: FC<TImageUploadForm> = ({ data, className = '', setImageId, imageClassName = '' }) => {
  const [uploadImage, uploadImageStates] = useUploadImageMutation();
  const [image, setImage] = useState<TImage | undefined>(undefined);

  useEffect(() => {
    if (image) {
      setImageId(image);
    }
  }, [image]);

  useEffect(() => {
    if (data?.id) {
      setImage(data);
    }
    return () => {
      setImage(undefined);
    };
  }, [data]);

  useEffect(() => {
    if (uploadImageStates.isError) {
      toast.error(getExceptionMessage(uploadImageStates.error));
    }
  }, [uploadImageStates]);

  const props: UploadProps = {
    showUploadList: false,
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        if (!uploadImage) {
          throw new Error('Upload image mutation is not available');
        }

        const formData = new FormData();
        formData.append('image', file);
        const uploadedImage = await uploadImage(formData).unwrap();

        if (!onSuccess) {
          throw new Error('onSuccess callback is not available');
        }

        setImage(uploadedImage);
        onSuccess({}, undefined);
      } catch (error) {
        if (!onError) {
          throw new Error('onError callback is not available');
        }
        if (error instanceof Error) {
          onError(error);
        }
      }
    },
  };

  return (
    <div className={`flex flex-col gap-[10px] ${className}`}>
      {image && <UploadedImage data={image} className={imageClassName} />}
      <Upload {...props}>
        <Button icon={<UploadOutlined />} loading={uploadImageStates.isLoading} disabled={uploadImageStates.isLoading}>
          Загрузить
        </Button>
      </Upload>
    </div>
  );
};

export default ImageUpload;
