import { api } from '../index';
import {
  ArticleStatusEnum,
  TArticle,
  TCreateArticle,
  TGetArticlesParams,
  TPaginationDataRes,
  TSuccessRes,
  TUpdateArticle,
} from '../../../core/types';

export const articlesApi = api.injectEndpoints({
  endpoints: builder => ({
    createArticle: builder.mutation<TSuccessRes, TCreateArticle>({
      query: body => ({
        url: `/articles/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['articles'],
    }),
    getPreviewArticleUuid: builder.mutation<{ uuid: string }, { id: number }>({
      query: ({ id }) => ({
        url: `/articles/preview/${id}`,
        method: 'POST',
      }),
    }),
    updateArticle: builder.mutation<TSuccessRes, { id: number; body: TUpdateArticle }>({
      query: ({ id, body }) => ({
        url: `/articles/update/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['articles'],
    }),
    changeArticleStatus: builder.mutation<TSuccessRes, { id: number; status: ArticleStatusEnum }>({
      query: ({ id, status }) => ({
        url: `/articles/change-status/${id}`,
        method: 'PATCH',
        params: {
          status,
        },
      }),
      invalidatesTags: ['articles'],
    }),
    raiseUpArticle: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/articles/raise-up/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['articles'],
    }),
    cancelRaiseUpArticle: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/articles/raise-up/cancel/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['articles'],
    }),
    getArticleById: builder.query<TArticle, { id: number }>({
      query: ({ id }) => ({
        url: `/articles/one/protected/?id=${id}`,
      }),
    }),
    getArticles: builder.query<TPaginationDataRes<TArticle>, { params: TGetArticlesParams }>({
      query: ({ params }) => ({
        url: `/articles/many/protected`,
        params,
      }),
      providesTags: ['articles'],
    }),
    deleteArticle: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/articles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['articles'],
    }),
  }),
});

export const {
  useCreateArticleMutation,
  useUpdateArticleMutation,
  useGetPreviewArticleUuidMutation,
  useRaiseUpArticleMutation,
  useCancelRaiseUpArticleMutation,
  useChangeArticleStatusMutation,
  useGetArticleByIdQuery,
  useLazyGetArticleByIdQuery,
  useGetArticlesQuery,
  useLazyGetArticlesQuery,
  useDeleteArticleMutation,
} = articlesApi;
