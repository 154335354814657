import CategoriesTable from '../../components/tables/categories.table';
import CustomTitle from '../../components/ui/custom-title';

const Categories = () => {
  return (
    <>
      <CustomTitle>Категории</CustomTitle>
      <CategoriesTable />
    </>
  );
};

export default Categories;
