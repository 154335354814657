export const errorTranslations: Record<string, string> = {
  'Invalid email or password': 'Неверный логин или пароль',
  'Failed to change password': 'Проверьте пароли!',
  'Email already exists': 'Пользователь с таким email уже существует',
  'Creator already exists': 'Пользователь с таким email уже существует',
  'You cannot delete this category': 'Вы не можете удалить данную категорию!',
  'Category already exists': 'Категория с таким названием уже существует',
  'Topic already exists': 'Тема уже существует',
  'You cannot update this category': 'Вы не можете редактировать данную категорию!',
  'Too many attempts. Please try again in 30 minutes.': 'Превышено кол-во попыток входа, попробуйте через 30 мин.',
  'Article already exist in banner': 'Выбранная статья уже добавлена в баннер',
  'Invalid picture type': 'Неподдерживаемый формат изображения, допустимые форматы: jpg/jpeg/png/gif',
  'File too large': 'Превышен размер загружаемого файла! Максимальный размер загружаемого фото 5мб',
};
