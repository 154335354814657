import { FC, useEffect } from 'react';
import { TCommunityTopic, TFormModal } from '../../core/types';
import { Form, Input, Modal } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';
import {
  useCreateCommunityTopicMutation,
  useUpdateCommunityTopicMutation,
} from '../../store/services/community-topics';

const CommunityTopicsFormModal: FC<TFormModal<TCommunityTopic>> = ({ open, data, setClose }) => {
  const [createCommunityTopic, createCommunityTopicStates] = useCreateCommunityTopicMutation();
  const [updateCommunityTopic, updateCommunityTopicStates] = useUpdateCommunityTopicMutation();
  const isLoading = createCommunityTopicStates.isLoading || updateCommunityTopicStates.isLoading;
  const [form] = Form.useForm();
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  useEffect(() => {
    if (data && open) {
      form.setFieldsValue(data);
    }
  }, [open]);

  useEffect(() => {
    if (createCommunityTopicStates.isSuccess) {
      toast.success('Тема создана');
      handleCloseModal();
    }
  }, [createCommunityTopicStates.isSuccess]);

  useEffect(() => {
    if (updateCommunityTopicStates.isSuccess) {
      toast.success('Тема изменена');
      handleCloseModal();
    }
  }, [updateCommunityTopicStates.isSuccess]);

  useEffect(() => {
    if (updateCommunityTopicStates.isError) {
      toast.error(getExceptionMessage(updateCommunityTopicStates.error));
    }
    if (createCommunityTopicStates.isError) {
      toast.error(getExceptionMessage(createCommunityTopicStates.error));
    }
  }, [updateCommunityTopicStates.isError, createCommunityTopicStates.isError]);

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      if (data) {
        return updateCommunityTopic({ id: data.id, body: formData });
      }
      return createCommunityTopic(formData);
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      title={`${data ? 'Редактировать' : 'Создать'} комьюнити тему`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      confirmLoading={isLoading}
      okText={data ? 'Сохранить' : 'Создать'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        <FormItem
          label={'Название'}
          name={'name'}
          rules={[
            {
              required: true,
              message: 'Поле обязательное!',
            },
            {
              min: 3,
              message: 'Минимальная длина 3 знаков!',
            },
          ]}
        >
          <Input size={'large'} />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default CommunityTopicsFormModal;
