import { FC, useEffect } from 'react';
import { RolesEnum, TAd, TFormModal, TImage } from '../../core/types';
import { DatePicker, Form, Input, Modal } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { toast } from 'react-toastify';
import { getExceptionMessage, getValueFormDatePicker, getValueFormDatePickerProps } from '../../core/utils';
import { useCreateAdMutation, useUpdateAdMutation } from '../../store/services/ads';
import ImageUpload from '../image-upload';
import TextArea from 'antd/es/input/TextArea';
import dayjs, { Dayjs } from 'dayjs';
import { RU_DATE } from '../../core/config';
import { useAppSelector } from '../../core/hooks/redux';

const AdsFormModal: FC<TFormModal<TAd>> = ({ open, data, setClose }) => {
  const { role } = useAppSelector(state => state.authReducer);
  const isSuperAdmin = role === RolesEnum.SUPER_ADMIN;
  const [createAd, createAdStates] = useCreateAdMutation();
  const [updateAd, updateAdStates] = useUpdateAdMutation();
  const isLoading = createAdStates.isLoading || updateAdStates.isLoading;
  const [form] = Form.useForm();
  const startDate = Form.useWatch('startDate', form);
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
    if (data) {
      form.setFieldsValue(data);
    }
  }, [open, data]);

  useEffect(() => {
    if (createAdStates.isSuccess) {
      toast.success('Рекламный блок создан');
      handleCloseModal();
    }
  }, [createAdStates.isSuccess]);

  useEffect(() => {
    if (updateAdStates.isSuccess) {
      toast.success('Рекламный блок изменен');
      handleCloseModal();
    }
  }, [updateAdStates.isSuccess]);

  useEffect(() => {
    if (updateAdStates.isError) {
      toast.error(getExceptionMessage(updateAdStates.error));
    }
    if (createAdStates.isError) {
      toast.error(getExceptionMessage(createAdStates.error));
    }
  }, [updateAdStates.isError, createAdStates.isError]);

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      if (data) {
        return updateAd({ id: data.id, body: formData });
      }
      return createAd(formData);
    } catch (error) {
      return;
    }
  };

  const setImage = (data: TImage) => {
    form.setFieldValue('imageId', data.id);
    form.validateFields();
  };
  const disabledStartDate = (current: Dayjs | null) => {
    const today = dayjs(); // Текущая дата
    const selectedDate = dayjs(current); // Выбранная пользователем дата
    return selectedDate.startOf('day').isBefore(today.startOf('day'));
  };

  const disabledEndDate = (current: Dayjs | null) => {
    const selectedStartDate = dayjs(startDate);
    return current && startDate ? current.isBefore(selectedStartDate.add(1, 'day'), 'day') : false;
  };

  return (
    <Modal
      title={`${data ? 'Редактировать' : 'Создать'} рекламный блок`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmit}
      confirmLoading={isLoading}
      onCancel={handleCloseModal}
      okText={data ? 'Сохранить' : 'Создать'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        {isSuperAdmin && (
          <>
            <FormItem
              label={'Фото'}
              name={'imageId'}
              rules={[
                {
                  required: true,
                  message: 'Фото обязательное!',
                },
              ]}
            >
              <ImageUpload
                className={'max-w-[392px] '}
                imageClassName={'aspect-[1/1]'}
                setImageId={setImage}
                data={data?.image}
              />
            </FormItem>
            <FormItem
              label={'Заголовок'}
              name={'title'}
              rules={[
                {
                  required: true,
                  message: 'Поле обязательное!',
                },
                {
                  min: 5,
                  message: 'Минимальная длина 5 знаков!',
                },
              ]}
            >
              <Input size={'large'} />
            </FormItem>
            <FormItem
              label={'Описание'}
              name={'description'}
              rules={[
                {
                  required: true,
                  message: 'Поле обязательное!',
                },
                {
                  min: 5,
                  message: 'Минимальная длина 5 знаков!',
                },
              ]}
            >
              <TextArea size={'large'} />
            </FormItem>
            <FormItem
              label={'Ссылка на ресурс'}
              name={'url'}
              rules={[
                {
                  required: true,
                  message: 'Поле обязательное!',
                },
                {
                  type: 'url',
                  message: 'Введите корректную ссылку',
                },
              ]}
            >
              <Input size={'large'} />
            </FormItem>
          </>
        )}
        <FormItem
          label={'Дата начала показа'}
          name={'startDate'}
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
          rules={[
            {
              required: true,
              message: 'Поле обязательное!',
            },
          ]}
        >
          <DatePicker className={'w-full'} format={RU_DATE} disabledDate={disabledStartDate} />
        </FormItem>
        <FormItem
          label={'Дата окончания показа'}
          name={'endDate'}
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
          rules={[
            {
              required: true,
              message: 'Поле обязательное!',
            },
          ]}
        >
          <DatePicker disabled={!startDate} format={RU_DATE} disabledDate={disabledEndDate} className={'w-full'} />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AdsFormModal;
