import { Button, Dropdown, MenuProps, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { RolesEnum, TCommunityTopic, TImage, TUser } from '../../core/types';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getExceptionMessage } from '../../core/utils';
import UploadedImage from '../ui/uploaded-image.ui';
import { useDeleteUserMutation, useGetAdminsQuery } from '../../store/services/users';
import AdminsFormModal from '../ modals/admins-form.modal';
import { useAppSelector } from '../../core/hooks/redux';

const { confirm } = Modal;

const AdminsTable = () => {
  const admins = useGetAdminsQuery();
  const [deleteAdmin, deleteAdminStates] = useDeleteUserMutation();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState<TUser | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  useEffect(() => {
    if (deleteAdminStates.isSuccess) {
      toast.success('Администратор удален');
    }
  }, [deleteAdminStates.isSuccess]);

  useEffect(() => {
    if (deleteAdminStates.isError) {
      toast.error(getExceptionMessage(deleteAdminStates.error));
    }
  }, [deleteAdminStates.isError]);

  const getColumns = (): ColumnsType<TUser> => {
    const columns: ColumnsType = [
      {
        title: 'Фото',
        dataIndex: 'image',
        key: 'image',
        align: 'center',
        className: 'w-[80px]',
        render: (data: TImage) => {
          return (
            <div className={'w-[70px] h-[70px] text-[40px] mx-auto'}>
              {data ? (
                <UploadedImage data={data} preview={false} className={'aspect-[1/1]'} />
              ) : (
                <div className={'w-full h-full border-[1px] rounded-[15px]'}>
                  <UserOutlined />
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Имя',
        dataIndex: 'username',
        key: 'username',
        align: 'center',
        className: 'min-w-[150px]',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        className: 'min-w-[210px] max-w-[350px]',
      },
    ];

    if (role === RolesEnum.SUPER_ADMIN) {
      columns.push({
        title: 'Действия',
        dataIndex: 'actions',
        align: 'center',
        key: 'actions',
        className: 'w-[50px]',
        render: (_, data: TCommunityTopic) => {
          return (
            <Dropdown menu={{ items: renderDropDownItems(data.id) }}>
              <SettingOutlined style={{ fontSize: '20px' }} />
            </Dropdown>
          );
        },
      });
    }

    return columns;
  };

  const handleCloseModal = () => {
    setSelectedAdmin(undefined);
    setIsFormModalOpen(false);
  };

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        onClick: () => {
          const ad = admins?.data?.find(el => el.id === id);
          setSelectedAdmin(ad);
          setIsFormModalOpen(true);
        },
        label: 'Редактировать',
      },
      {
        key: 'delete',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить администратора?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Удалить',
            onOk() {
              deleteAdmin({ id });
            },
          });
        },
        label: 'Удалить',
        danger: true,
      },
    ];

    return items;
  };

  return (
    <>
      <div className={'pt-[15px] pb-[25px]'}>
        <Button type="primary" size={'large'} onClick={() => setIsFormModalOpen(true)}>
          Создать
        </Button>
      </div>
      <Table scroll={{ x: true }} pagination={false} dataSource={admins?.data} columns={getColumns()} rowKey="id" />
      <AdminsFormModal open={isFormModalOpen} setClose={handleCloseModal} data={selectedAdmin} />
    </>
  );
};

export default AdminsTable;
