import React from 'react';
import CustomTitle from '../../components/ui/custom-title';
import ArticleForm from '../../components/forms/article.form';

const CreateArticlePage = () => {
  return (
    <>
      <CustomTitle>Создать статью</CustomTitle>
      <ArticleForm />
    </>
  );
};

export default CreateArticlePage;
