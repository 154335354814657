import { api } from '../index';
import { TAd, TCreateAd, TPagination, TPaginationDataRes, TSuccessRes, TUpdateAd } from '../../../core/types';

export const adsApi = api.injectEndpoints({
  endpoints: builder => ({
    createAd: builder.mutation<TSuccessRes, TCreateAd>({
      query: body => ({
        url: `/ads/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ads'],
    }),
    getAds: builder.query<TPaginationDataRes<TAd>, { params: TPagination }>({
      query: ({ params }) => ({
        url: `/ads/get-all`,
        params,
      }),
      providesTags: ['ads'],
    }),
    updateAd: builder.mutation<TSuccessRes, { id: number; body: TUpdateAd }>({
      query: ({ id, body }) => ({
        url: `/ads/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ads'],
    }),
    deleteAd: builder.mutation<TSuccessRes, { id: number }>({
      query: ({ id }) => ({
        url: `/ads/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ads'],
    }),
  }),
});

export const { useGetAdsQuery, useCreateAdMutation, useUpdateAdMutation, useDeleteAdMutation } = adsApi;
