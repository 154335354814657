import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserDataFromAccessToken } from '../../../core/utils';
import { RolesEnum } from '../../../core/types';

export interface User {
  email: string;
  id: number;
}

interface IInitialState {
  user: null | User;
  accessToken: string | null;
  refreshToken?: string | null;
  role: RolesEnum;
}

const initialState = {
  user: null,
  accessToken: localStorage.getItem('accessToken'),
  role: RolesEnum.PUBLIC,
} as IInitialState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserByLogin: (state, action: PayloadAction<IInitialState>) => {
      localStorage.setItem('accessToken', action.payload.accessToken as string);
      localStorage.setItem('refreshToken', action.payload.refreshToken as string);
      state.role = action.payload.role;
      state.accessToken = action.payload.accessToken;
      state.user = {
        email: action.payload.user!.email,
        id: action.payload.user!.id,
      };
    },
    setUser: state => {
      if (!localStorage.getItem('accessToken')) {
        return;
      }
      const user = getUserDataFromAccessToken(localStorage.getItem('accessToken') as string);
      state.role = user.role;
      state.user = {
        email: user.user!.email,
        id: user.user!.id,
      };
    },
    logout: state => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.role = RolesEnum.PUBLIC;
      state.accessToken = null;
      state.user = null;
    },
  },
});

export const { setUser, setUserByLogin, logout } = authSlice.actions;
export default authSlice.reducer;
