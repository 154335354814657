import { theme, ThemeConfig } from 'antd';

const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: '#383838',
    borderRadius: 12,
    // colorBgContainer: '#f6ffed',
  },
  components: {
    Button: {
      controlOutline: 'none',
    },
    Layout: {
      headerBg: '#ffffff',
      bodyBg: '#efefef',
      siderBg: '#000000',
      triggerBg: '#151515',
    },
    Menu: {
      itemBg: '#000',
      subMenuItemBg: '#151515',
      colorText: '#fff',
      colorLinkActive: 'red',
      itemSelectedColor: '#6784ff',
      itemSelectedBg: '#252525',
      popupBg: '#000',
      itemHoverBg: '#2a2a2a',
    },
    Table: {
      headerBg: '#151515',
      headerColor: '#fff',
      rowHoverBg: '#eaeaea',
    },
    Select: {
      optionSelectedBg: '#e8e8e8',
    },
  },
  algorithm: theme.defaultAlgorithm,
};

export default themeConfig;
