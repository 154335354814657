import { FC, useEffect } from 'react';
import { RolesEnum, TFormModal, TImage, TUser } from '../../core/types';
import { Form, Input, Modal } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { toast } from 'react-toastify';
import { getExceptionMessage, normalizePassword } from '../../core/utils';
import ImageUpload from '../image-upload';
import { useCreateAdminMutation, useLazyGetCurrentUserQuery, useUpdateAdminMutation } from '../../store/services/users';

const AdminsFormModal: FC<TFormModal<TUser>> = ({ open, data, setClose, refetchCurrentUser }) => {
  const [createAdmin, createAdminStates] = useCreateAdminMutation();
  const [updateAdmin, updateAdminStates] = useUpdateAdminMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const isLoading = createAdminStates.isLoading || createAdminStates.isLoading;
  const [form] = Form.useForm();
  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  useEffect(() => {
    form.resetFields();
    if (data && open) {
      form.setFieldsValue(data);
    }
  }, [open]);

  useEffect(() => {
    if (createAdminStates.isSuccess) {
      toast.success('Администратор создан');
      handleCloseModal();
    }
  }, [createAdminStates.isSuccess]);

  useEffect(() => {
    if (updateAdminStates.isSuccess) {
      toast.success(refetchCurrentUser ? 'Данные изменены' : 'Данные администратора изменены');
      if (refetchCurrentUser) {
        getCurrentUser();
      }
      handleCloseModal();
    }
  }, [updateAdminStates.isSuccess]);

  useEffect(() => {
    if (updateAdminStates.isError) {
      toast.error(getExceptionMessage(updateAdminStates.error));
    }
    if (createAdminStates.isError) {
      toast.error(getExceptionMessage(createAdminStates.error));
    }
  }, [updateAdminStates.isError, createAdminStates.isError]);

  const setImage = (data: TImage) => {
    form.setFieldValue('imageId', data.id);
    form.validateFields();
  };
  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      if (data) {
        return updateAdmin({ id: data.id, body: formData });
      }
      return createAdmin(formData);
    } catch (error) {
      return;
    }
  };

  return (
    <Modal
      title={`${data ? 'Редактировать' : 'Создать'} ${refetchCurrentUser ? 'профиль' : 'администратора'} `}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      confirmLoading={isLoading}
      okText={data ? 'Сохранить' : 'Создать'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit} className={'py-5'}>
        <FormItem label={'Фото'} name={'imageId'}>
          <ImageUpload
            className={'max-w-[230px] '}
            imageClassName={'aspect-[1/1]'}
            setImageId={setImage}
            data={data?.image}
          />
        </FormItem>
        <Form.Item
          label={'Полное имя'}
          name="username"
          rules={[
            { required: true, message: 'Укажите имя!' },
            {
              min: 3,
              message: 'Минимальная длина 3 знака!',
            },
          ]}
        >
          <Input size={'large'} type="text" placeholder="Полное имя" />
        </Form.Item>
        {data?.role !== RolesEnum.SUPER_ADMIN && (
          <Form.Item
            name="email"
            label={'Email'}
            rules={[
              { required: true, message: 'Укажите email!' },
              { type: 'email', message: 'Некорректный формат email!' },
            ]}
          >
            <Input size={'large'} type={'email'} placeholder="Email" />
          </Form.Item>
        )}
        {!data && (
          <Form.Item
            label={'Пароль'}
            name="password"
            getValueFromEvent={normalizePassword}
            rules={[
              { required: true, message: 'Укажите пароль!' },
              {
                min: 10,
                message: 'Минимальная длина 10 знаков!',
              },
            ]}
          >
            <Input.Password size={'large'} type="password" placeholder="Пароль" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AdminsFormModal;
