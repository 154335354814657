import ReactDOM from 'react-dom/client';
import './core/styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { Provider } from 'react-redux';
import { setupStore } from './store';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import 'dayjs/locale/ru';
import ru_RU from 'antd/es/locale/ru_RU';
import themeConfig from './core/config/theme.config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

ConfigProvider.config({
  theme: themeConfig,
});

root.render(
  <>
    <ConfigProvider theme={themeConfig} locale={ru_RU}>
      <Provider store={setupStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ConfigProvider>
  </>
);
