import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useAppDispatch } from '../../core/hooks/redux';
import { setUser } from '../../store/slices/auth/AuthSlice';
import { useSignInMutation } from '../../store/services/auth';
import { toast } from 'react-toastify';
import { getExceptionMessage, normalizePassword } from '../../core/utils';

const AuthForm = () => {
  const [login, { isError, error }] = useSignInMutation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(getExceptionMessage(error));
    }
  }, [isError]);

  const onFinish = async () => {
    const data = form.getFieldsValue();
    await login(data);
    dispatch(setUser());
  };

  return (
    <div className={'h-full flex justify-center items-center p-6'}>
      <Form
        form={form}
        size={'large'}
        name="normal_login"
        className={'w-full max-w-[500px]'}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Укажите email!' },
            { type: 'email', message: 'Некорректный формат email!' },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} type={'email'} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Укажите пароль!' }]}
          getValueFromEvent={normalizePassword}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={'w-full'}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuthForm;
